export default [
    {
        path: '/categoria/:idCategoria/ver',
        name: 'categoria.ver',
        component: () => import('../../pages/categoria/index').then(m => m.default || m),
        meta: { gtm: 'Categorias' },
        children: [
            {
                path: '/categoria/:idCategoria/sub/:idSubCategoria',
                name: 'categoria.ver.subcategoria',
                component: () => import('../../pages/categoria/index').then(m => m.default || m),
                meta: { gtm: 'Categorias' },
            }
        ]
        
    }
]
