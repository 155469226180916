<template>
    <modal ref="modalAdvertencia" titulo="Advertencia" :adicional="textBoton" @adicional="accion">
        <div class="row mx-0 j-center mb-3">
            <div class="col-auto text-center text-general f-15">
                {{ mensaje }}
            </div>
        </div>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    data(){
        return {
            mensaje:'',
            textBoton:'Accion'

        }
    },
    computed:{
        ...mapGetters({
            response:'asignarTiendas/respuesta'
        })    
    },
    methods: {
        toggle(){

            switch (this.response.code){
            case 1:
                this.mensaje = 'Por favor agregue una direccion de entrega'
                this.textBoton = 'Agregar Dirección'
                break; 
            case 2:
            case 4:
            case 5:
                this.mensaje = 'Lo sentimos! Error interno, pongase en contacto con el administrador'
                this.textBoton = 'Salir'
                break; 
            case 3:
                this.mensaje = 'No tenemos un Centro de distribución asignado en tu área, prueba cambiando de dirección'
                this.textBoton = 'Agregar Dirección'
                break;
            
            default:
                this.mensaje = 'Lo sentimos! Falta parametrizacion'
                this.textBoton = 'Salir'
                break;
            }


            this.$refs.modalAdvertencia.toggle();
            this.$store.commit('asignarTiendas/setBanderaAdvertencia',false)
        },
        accion(){
            this.$refs.modalAdvertencia.toggle();
            if(this.textBoton == 'Agregar Dirección'){
                this.$emit('accion')
                return
            }
            if(this.textBoton == 'Salir'){
                this.$router.push({name:'login'})
            }
        }
    }
}
</script>
