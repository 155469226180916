
<template>
	<div>
		<div id="googleMap" ref="googleMap" :style="{ height }" class="googleMap mt-1" />
	</div>
</template>
<script>

export default {
	props: {
		accion: {
			type: String,
			default: 'nuevo'
		},
		changeCoordinates: {
			type: Boolean,
			default: false
		},
		coordinates: {
			type: Object,
			default: () => ({ lat: 0, lng: 0 })
		},
		query: {
			type: String,
			default: ''
		},
		place: {
            type: Object,
            default:() => {}
        },
        icon: {
            type: String,
            default: ''
        },
        height: {
            type: String,
            default: '53vh'
        },
        zoom: {
            type: Number,
            default: 16
        },

	},
	data() {
		return {
			direcciones: [],
			googleMaps: null,
			direccion: '',
			servicePlaces: null,
			mapa: null,
			marcador: null,
		}
	},

	watch: {
		query() {
			this.buscarDireccion(this.query);
		},
		place() {
			this.seleccionarDireccion();
		},
		changeCoordinates() {
			this.montarMapa();
		},
	},

	async mounted() {
		await this.capturarCoordenadasUsuario();
		await this.inicializarGoogleMaps();
	},

	methods: {
		async capturarCoordenadasUsuario() {
			if (this.accion == 'nuevo') {
				this.$getLocation({})
					.then(coordinates => {
						this.coordinates.lat = parseFloat(coordinates.lat.toFixed(4));
						this.coordinates.lng = parseFloat(coordinates.lng.toFixed(4));
					})
					.catch(error => {
						this.errorCatch(error)
					});
			}
		},

		async inicializarGoogleMaps() {
			this.googleMaps = await this.$iniciarGoogleMaps();
			await this.montarMapa();
		},

		async montarMapa() {
			await new Promise(async (resolve, reject) => {
				if (this.accion == 'nuevo') {
					resolve(await this.capturarCoordenadasUsuario());
				} else {
					resolve();
				}
			});
			// Montamos el mapa
			let lat = parseFloat(this.coordinates.lat);
			let lng = parseFloat(this.coordinates.lng);
			let zoom = parseInt(this.zoom);

			this.mapa = new this.googleMaps.Map(this.$refs.googleMap, {
				center: { lat, lng },
				zoom: zoom,
			});

			this.marcador = new this.googleMaps.Marker({
				position: { lat, lng },
				map: this.mapa,
				icon: this.icon,
				draggable: true
			});
			// Montamos listeners
			this.mountedListenersMap();
			// Montamos el servicio de autocompletado
			this.iniciarServiceAutocomplete();
		},

		iniciarServiceAutocomplete() {
			this.servicePlaces = new this.googleMaps.places.AutocompleteService();
		},

		buscarDireccion(value) {
			if (_.isEmpty(value)) return;

			this.servicePlaces.getPlacePredictions({
				input: value,
				componentRestrictions: {
					/**
					 * Buscara la dirección indicada en los siguientes paises:
					 * co = Colombia
					 * mx = México
					 * gt: Guatemala
					 */
					country: ['co', 'mx', 'gt'],
				}
			}, this.buscarCoincidencias)
			this.$emit('resultadosBusqueda', this.direcciones);
		},

		buscarCoincidencias(predictions, status) {
			this.direcciones = predictions;
			if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
				this.direcciones = []
				return
			}
			this.direcciones = predictions.map(prediction => {
				return {
					place_id: prediction.place_id,
					value: prediction.description,
				}
			});
		},

		seleccionarDireccion() {
			if (_.isEmpty(this.place)) return;

			const geocoder = new this.googleMaps.Geocoder();

			geocoder.geocode({ 'placeId': this.place.place_id }, async (results, status) => {
				if (status == google.maps.GeocoderStatus.OK) {

					let lat = results[0].geometry.location.lat();
					let lng = results[0].geometry.location.lng();

					let direccionSelect = {};

					direccionSelect.place_id = this.place.place_id;
					direccionSelect.direccion = this.place.value;
					direccionSelect.lat = lat;
					direccionSelect.lng = lng;

					let componentes = results[0].address_components;
					let componentesLength = componentes.length;

					for (let i = 0; i < componentesLength; i++) {
						let types = componentes[i].types;
						for (let x = 0; x < types.length; x++) {
							if (types[x] == 'country') {
								direccionSelect.pais = componentes[i].long_name;
							} else if (types[x] == 'administrative_area_level_1') {
								direccionSelect.departamento = componentes[i].long_name;
							} else if (types[x] == 'administrative_area_level_2' || types[x] == 'locality' || types[x] == 'sublocality') {
								direccionSelect.ciudad = componentes[i].long_name;
							} else if (types[x] == 'postal_code') {
								direccionSelect.codigoPostal = componentes[i].long_name;
							}
						}
					}

					// Cambiamos las coordenadas del mapa
					this.cambiarCoordenadasMapa(lat, lng);
					this.$emit('setDireccion', direccionSelect);
				}
			});
		},
		async drawGeocercas(geocercas) {

			for (const geo of geocercas) {
				let puntos = geo.puntos.map(function (p) {
					p.lat = parseFloat(p.lat)
					p.lng = parseFloat(p.lng)
					return p
				})
				let geocerca = new this.googleMaps.Polygon({
					paths: puntos,
					strokeColor: "#000000",
					strokeOpacity: 0.8,
					strokeWeight: 3,
					fillColor: "#6F6E6E",
					fillOpacity: 0.35,
					map: this.mapa
				});

				let infoWindow = new this.googleMaps.InfoWindow();
				geocerca.addListener("click", (event) => {
					let contentString =
						`<b>${geo.nombre}</b><br>` +
						"Descripcion: <br>" +
						geo.descripcion;
					infoWindow.setContent(contentString);
					infoWindow.setPosition(event.latLng);
					infoWindow.open(this.mapa);
				});
			}
		},

		mountedListenersMap() {
			this.listenerClickMapa();
			this.listenerDragendMarket();
		},

		listenerClickMapa() {
			// Evento que escucha cuando dan click sobre algún lugar valido dentro del mapa.
			this.mapa.addListener('click', async (event) => {
				if (!event.placeId) return this.reloadMarket();
				this.setPlace(event.placeId);
			});
		},

		listenerDragendMarket() {
			// Evento que escucha cuando el marcador se ha movido y de acuerdo a un radio en metros, captura los lugares en el cual se ha posicionado.
			this.marcador.addListener('dragend', (event) => {
				this.coordinates.lat = parseFloat(event.latLng.lat());
				this.coordinates.lng = parseFloat(event.latLng.lng());

				let pyrmont = new google.maps.Map(event, {
					zoom: 8,
					center: { lat: 40.731, lng: -73.997 },
				});
				const geocoder = new google.maps.Geocoder();

				const latlng = {
					lat: this.coordinates.lat = parseFloat(event.latLng.lat()),
					lng: this.coordinates.lng = parseFloat(event.latLng.lng()),
				};

				return this.geocodeLatLng(geocoder, pyrmont, latlng)

				// Según las nuevas coordenadas, realizamos búsqueda.
				// let service = new this.googleMaps.places.PlacesService(this.mapa);
				// service.nearbySearch(request, (results, status) => {
				//     if (status == this.googleMaps.places.PlacesServiceStatus.OK){
				//         this.direcciones = results.map(result => {
				//             return {
				//                 place_id: result.place_id,
				//                 value: result.name,
				//             }
				//         });
				//         this.$emit('resultadosBusquedaMarcador', this.direcciones);
				//     }
				// });
			});
		},

		async geocodeLatLng(geocoder, map, latlng) {
			geocoder
				.geocode({ location: latlng })
				.then((response) => {
					if (response.results[0]) {
						map.setZoom(11);
						this.$emit('resultadosBusquedaMarcador', [{value: response.results[0].formatted_address, place_id: response.results[0].place_id }]);
					} else {
						console.log("No results found");
					}
				})
				.catch((e) => window.alert("Geocoder failed due to: " + e));
		},

		async setPlace(placeId) {
			// Por medio del placeId realizará la búsqueda del lugar.
			const newPlace = await new Promise((resolve, reject) => {
				var service = new this.googleMaps.places.PlacesService(this.mapa);
				service.getDetails({ placeId: placeId }, (place, status) => {
					if (status === 'OK') {
						let lat = parseFloat(place.geometry.location.lat());
						let lng = parseFloat(place.geometry.location.lng());

						let direccionSelect = {};
						direccionSelect.place_id = placeId;
						direccionSelect.direccion = `${place.name}, ${place.formatted_address}`;
						direccionSelect.lat = lat;
						direccionSelect.lng = lng;

						let componentes = place.address_components;
						let componentesLength = componentes.length;

						for (let i = 0; i < componentesLength; i++) {
							let types = componentes[i].types;
							for (let x = 0; x < types.length; x++) {
								if (types[x] == 'country') {
									direccionSelect.pais = componentes[i].long_name;
								} else if (types[x] == 'administrative_area_level_1') {
									direccionSelect.departamento = componentes[i].long_name;
								} else if (types[x] == 'administrative_area_level_2' || types[x] == 'locality' || types[x] == 'sublocality') {
									direccionSelect.ciudad = componentes[i].long_name;
								} else if (types[x] == 'postal_code') {
									direccionSelect.codigoPostal = componentes[i].long_name;
								}
							}
						}
						resolve(direccionSelect);
					}
				});
			});
			this.cambiarCoordenadasMapa(newPlace.lat, newPlace.lng);
			this.$emit('setDireccionDetail', newPlace);
		},

		async cambiarCoordenadasMapa(lat, lng) {
			// Montamos el mapa nuevamente con las coordenadas nuevas.
			this.mapa = new this.googleMaps.Map(this.$refs.googleMap, {
				center: { lat, lng },
				zoom: 17,
			});

			this.marcador = new this.googleMaps.Marker({
				position: { lat, lng },
				map: this.mapa,
				//icon: this.icon
			});

			this.coordinates.lat = lat;
			this.coordinates.lng = lng;
			// Montamos listeners
			this.mountedListenersMap();
		},

		reloadMarket() {
			// Eliminamos el marcador actual.
			this.marcador.setMap(null);
			setTimeout(() => {
				// Asignamos un nuevo marcador.
				const lat = this.coordinates.lat;
				const lng = this.coordinates.lng;

				this.marcador = new this.googleMaps.Marker({
					position: { lat, lng },
					map: this.mapa,
					icon: this.icon,
					draggable: true
				});
				this.marcador.setMap(this.mapa);
				// Montamos el listener del marcador.
				this.listenerDragendMarket();
			}, 200);
		}
	},
}
</script>
<style lang="scss" scoped>
.googleMap {
	position: relative;
	width: 100%;
}
</style>
