/**
 * En este store se van a guardar todas las variables globales que servirán para realizar búsquedas a nivel global en la aplicación
 */

export const state = {
    queryProductInvitado: ''
}

export const getters = {
    getQueryProductInvitado: (state) => {
        return state.queryProductInvitado
    }
}

export const mutations = {
    setQueryProductInvitado: (state, payload) => {
        state.queryProductInvitado = payload
    },
}
