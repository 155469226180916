export default [
    {
        path: '/mis-canjes',
        name: 'mis-canjes',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/misCanjes').then(m => m.default || m),
    },
    {
        path: '/mis-canjes/detalle/:id_canje',
        name: 'mis-canjes.detalle',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/misCanjes/detalleCanje').then(m => m.default || m),
    },
]
