import axios from 'axios'

const api = 'pedidos'

const Pedidos = {
    getMotivosReclamos: () => axios(`${api}/motivos-reclamos`),
    createReclamo: (params) => axios.post(`${api}/create-reclamo`,params),
    createSoporte: (params) => axios.post(`${api}/create-soporte`,params),
    finalizarReclamo: (params) => axios.put(`${api}/finalizar-reclamo`,params),
    finalizarSoporte: (params) => axios.put(`${api}/finalizar-soporte`,params),

    getMotivos(tipo){
        return axios.get(`${tipo}/motivos`);
    },
    getChatPedido(params){
        return axios.get(`${api}/chat`, {params});
    },
    postChatPedido(model){
        return axios.post(`${api}/chat/save`, model);
    },
    postReclamo(model){
        return axios.post(`${api}/iniciar/reclamo`, model)
    },
    postSoporte(model){
        return axios.post(`${api}/iniciar/soporte`, model)
    },
    realizarPedido(form){
        return axios.post(`${api}`, form)
    },
    postCancelarPedido(model){
        return axios.post(`${api}/cancelar`, model)
    }

}

export default Pedidos