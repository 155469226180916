<template>
    <modal ref="modalProducto" titulo="Productos" :tamano="producto.kit ? 'modal-xl' : 'modal-lg'" no-cancelar no-adicional>
        <div class="row mx-0 pt-4 pl-4 py-5">
            <div class="col-5 px-0">
                <vueper-slides class="no-shadow text-muted2 h-vueper3" :arrows-outside="false" :visible-slides="1" :touchable="false" :autoplay="producto.imagenes.length > 1" :arrows="true">
                    <vueper-slide
                    v-for="(slide, idx) in producto.imagenes"
                    :key="idx"
                    >
                        <template v-slot:content>
                            <div class="row mx-0 j-center">
                                <img :src="slide ? slide : '/img/no-imagenes/mercado.svg'" class="obj-cover border br-12 img-product" width="266" height="266" />
                            </div>
                        </template>
                    </vueper-slide>
                    <template #arrow-left>
                        <div class="circled-arrow d-middle-center text-general border rounded-circle" style="width:24px;height:24px;">
                            <i class="icon-left-open f-12" />
                        </div>
                    </template>
                    <template #arrow-right>
                        <div class="circled-arrow d-middle-center text-general border rounded-circle" style="width:24px;height:24px;">
                            <i class="icon-right-open f-12" />
                        </div>
                    </template>
                </vueper-slides>
                <!-- <img :src="producto.imagenes.length === 1 ? producto.imagenes[0] : '/img/no-imagenes/mercado.svg'" class="obj-cover border br-12 img-product" width="266" height="266" /> -->
            </div>
            <div class="col-xl col-lg col-md col-sm-12 col-12 ml-4">
                <p class="f-20 text-general f-500 nombre-producto">
                    {{ producto.nombre }}
                </p>
                <p class="my-2 f-300">
                    <span class="f-400">Presentación:</span> {{ producto.presentacion }}
                </p>
                <div class="row mx-0">
                    <p v-if="producto.promocion" class="col-auto f-600 px-0 text-general f-24 px-0">
                        {{ convertMoney(funValor(promocion.promo_valor)) }}
                    </p> 
                    <p v-else class="col-auto f-600 px-0 text-general f-24 px-0">
                        {{ convertMoney(funValor(producto.precio)) }}
                    </p>
                    <p v-show="producto.promocion" class="col-auto f-600 ml-3 text-general2 f-24 px-0 descuento">
                        {{ convertMoney(funValor(producto.precio)) }}
                    </p>
                </div>
                <div class="row mx-0 mt-3 mb-4">
                    <div v-show="producto.promocion" class="bg-general px-2 f-12 br-20 py-1 text-white f-500 mr-3">
                        {{ promocion.texto }}
                    </div>
                    <p v-show="$usuario.tipo_impuesto === 1 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-12 text-center p-1">+ IVA</p>
                    <p v-show="$usuario.tipo_impuesto === 2 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-12 text-center p-1">IVA incluido</p>
                </div>
                <p class="text-gris2 f-15 my-4">
                    {{ producto.descripcion }}
                </p>
                <div class="row mx-0">
                    <div v-if="producto.cant_carrito <= 0" class="btn-general br-12 px-5" style="height:44px;">
                        <span class="w-100 h-100 d-middle-center cr-pointer" @click="$emit('agregar')">
                            Añadir
                            <i class="icon-cart-outline f-18" />
                        </span>
                    </div>
                    <div v-else class="d-middle">
                        <div>
                            <span class="f-500">{{ convertMoney(funValorEnCarrito(producto.total_carrito)) }}</span>
                            <p class="f-10">Total a pagar</p>
                        </div>
                        <i class="icon-trash-can-outline text-danger border br-5 f-20 border-danger ml-3 mr-2 cr-pointer" @click="$emit('eliminar')" />
                        <el-input-number
                        v-model="producto.cant_carrito"
                        :min="parseFloat(producto.compra_minima)"
                        :max="maximaCompra"
                        :step="parseFloat(producto.cantidad_minima)"
                        :step-strictly="entero"
                        :precision="precision"
                        size="medium" class="ml-auto"
                        style="width:190px;"
                        @change="$emit('update',$event)"
                        />
                    </div>
                </div>
            </div>
            <div v-show="producto.kit" class="col-xl col-lg col-md col-sm-12 col-12">
                <productos-kits ref="detalleKit" />
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    props:{
        producto:{
            type:Object,
            default(){return {}}
        },
        promocion:{
            type:Object,
            default(){return {}}
        }
    },
    data(){
        return {
            cant: 0,
            productosKit: false,
            favorito: false,
            imagenesPrueba: [
                'https://storage.googleapis.com/leeche_prueba/productos/122119264761ce231d6e9955.441282000.453020001640899357.jpeg',
                'https://storage.googleapis.com/leeche_prueba/productos/69800611861ce35306bcce0.869114570.441557001640903984.jpeg',
            ]
        }
    },
    computed: {
        entero(){
            if(this.producto.cantidad_tipo === 1){
                return true
            }else{
                return false
            }
        },
        precision(){
            if(this.producto.cantidad_tipo === 1){
                return 0
            }else{
                return 2
            }
        },
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
        maximaCompra(){
            if(this.producto.compra_maxima === 0){
                return 999999
            }
            if(this.producto.cant_carrito > this.producto.compra_maxima){
                return parseFloat(this.producto.cant_carrito)
            }
            return parseFloat(this.producto.compra_maxima)
        }
        
    },
    methods:{
        toggle(){
            this.$refs.modalProducto.toggle();
            if(this.producto.kit){
                this.getKit()
            }
        },
        async getKit(){
            await this.$refs.detalleKit.getProductosKit(this.producto.id_producto)
        },
        agregarFavorito(){
            this.favorito = !this.favorito;
        },
        funValor(value){
            if(this.$usuario.tipo_impuesto === 0){
                return value
            }
            if(this.$usuario.tipo_impuesto === 1){
                return value / (1 + this.impuesto / 100)
            }
            if(this.$usuario.tipo_impuesto === 2 || this.$usuario.tipo_impuesto === 3){
                return value
            }
            return 0
        },
        funValorEnCarrito(value){
            if(this.$usuario.tipo_impuesto === 0){
                return value
            }
            if(this.$usuario.tipo_impuesto === 1){
                return value
            }
            if(this.$usuario.tipo_impuesto === 2 || this.$usuario.tipo_impuesto === 3){
                return value * (1 + this.impuesto / 100)
            }
            return 0
        },
    }
}
</script>
<style lang="scss" scoped>
.pill{
    min-width: 24px;
    height: 24px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    box-shadow: 0px 2px 6px #0000000D;
    background-color: #000000;
    border-radius: 16px;
}
.circled-heart{
    width:24px;
    height:24px;
    top:10px;
    right:16px;
    box-shadow: 0px 2px 6px #0000000D;
}
</style>