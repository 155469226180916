import Vue from 'vue'

import HelperFecha from './Fecha'
import Humanizar from './Humanizar'

[
  HelperFecha,
  Humanizar
].forEach(Filter=>{
  Vue.use(Filter)
})
