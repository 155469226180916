<template>
    <el-popover
    ref="popoverProfile"
    placement="bottom"
    width="325"
    popper-class="br-8"
    class="ml-xl-3 ml-lg-3 ml-md-3 ml-sm-0 ml-0"
    trigger="click"
    @click.native="opcionesResponsive"
    >
        <template v-if="existeUsuario">
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 266px);">
                <div class="row mx-0 mt-2 a-center j-center mb-4 cr-pointer" @click="redirect('mi-perfil')">
                    <div class="circled-user p-1">
                        <img :src="usuario.avatar ? usuario.avatar : '/img/no-imagenes/sin_cliente.svg'" class="obj-cover rounded-circle" width="100%" height="100%" />
                    </div>
                    <div class="col px-2 text-general">
                        <p class="f-18 f-500">{{ usuario.nombre }}</p>
                        <span class="text-gris2 f-500 f-14">Ir al perfil</span> <i class="icon-right-open f-15" />
                    </div>
                </div>
                <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-shop f-20 text-general" />
                    </div>
                    <div class="col px-3 text-general" @click="redirect('home.tienda')">
                        Ir a la tienda
                    </div>
                </div>
                <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('pedidos')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-order f-20 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Mis pedidos
                    </div>
                </div>
                <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('pedidos.recurrentes')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-calendar f-20 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Pedidos recurrentes
                    </div>
                </div>
                <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('favoritos')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-heart text-red f-20 " />
                    </div>
                    <div class="col px-3 text-general">
                        Favoritos
                    </div>
                </div>
                <hr class="bg-gris mx-2" />
                <!-- Recompensas -->
                <div class="row mx-0 px-2 mb-3">
                    <div style="width:28px;" /> 
                    <div class="col px-3 text-gris2 f-17">
                        Recompensas
                    </div>
                </div>
                <div v-if="$config.menu.gaming" class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('recompensas')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-gem f-20 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Recompensas
                    </div>
                </div>
                <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="modalCupones()">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-discount f-17 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        cupones
                    </div>
                </div>
                <div v-if="$config.menu.gaming" class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('premios')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-gift f-20 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Premios
                    </div>
                </div>
                <div v-if="$config.menu.gaming" class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('mis-canjes')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-bidirectional f-17 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Mis canjes de premios
                    </div>
                </div>
                <div v-if="$config.menu.gaming" class="row mx-0 mt-5">
                    <div class="col-12">
                        <div class="btn-invita-gana d-middle pl-4 position-relative" @click="redirect('invita-gana')">
                            <span class="font-omnes f-22 text-white lh-16">Invita <br /> y gana</span>
                            <img src="/img/ilustraciones/gaming/grupo_recompensas.png" class="position-absolute" height="110" style="right:30px;max-width: 100%;top:-27px" />
                        </div>
                    </div>
                </div>
                <hr class="bg-gris mx-2" />
                <div class="row mx-0 px-2 mb-3">
                    <div style="width:28px;" /> 
                    <div class="col px-3 text-gris2 f-17">
                        Mi cuenta
                    </div>
                </div>
                <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('leecheros')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-client-cap f-18 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Mis {{ $config.vendedor }}
                    </div>
                </div>
                <div v-if="$config.menu.creditos" class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('creditos')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-credit-cancel f-15 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Créditos
                    </div>
                    <span class="text-general f-600">{{ convertMoney(usuario.total_credito) }}</span>
                </div>
                <div v-if="$config.menu.nomina" class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('convenio')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-handshake f-18 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Convenio de nómina 
                    </div>
                </div>
                <hr class="bg-gris mx-2" />
                <div class="row mx-0 px-2 mb-3">
                    <div style="width:28px;" /> 
                    <div class="col px-3 text-gris2 f-17">
                        Información
                    </div>
                </div>
                <div class="row mx-0 px-2 a-center j-center mb-3 cr-pointer" @click="redirect('soporte')">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-life f-20 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Ayuda
                    </div>
                </div>
                <hr class="bg-gris mx-2" />
                <div class="row mx-0 px-2 a-center j-center cr-pointer" @click="irCerrarSesion">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-logout f-18 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Cerrar sesion
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="custom-scroll overflow-auto">
                <div class="row mx-0 px-2 a-center j-center cr-pointer" @click="irCerrarSesion">
                    <div class="text-center" style="width:28px;">
                        <i class="icon-logout f-18 text-general" />
                    </div>
                    <div class="col px-3 text-general">
                        Cerrar sesion
                    </div>
                </div>
            </div>
        </template>
        <div slot="reference" class="d-flex cr-pointer user-select a-center">
            <img :src="usuario.avatar ? usuario.avatar : '/img/no-imagenes/sin_cliente.svg'" class="border obj-cover rounded-circle" width="40" height="40" />
            <i class="icon-chevron-down f-20 text-general" />
        </div>
    </el-popover>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            widthWindow: 0,
        }
    },
    computed: {
        ...mapGetters({
            existeUsuario: 'auth/existeUsuario',
            usuario: 'auth/obtenerUsuario',
        }),
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        async irCerrarSesion(){
            await this.$store.dispatch('auth/cerrarSesion')
            if(this.$route.name === 'home'){
                window.location.reload()
            }else {
                this.$router.push({name:'home'})
            }
        },
        handleResize(){
            this.widthWindow = window.innerWidth;
            this.heightWindow = window.innerHeight;
        },
        opcionesResponsive(){
            if (this.widthWindow > 950) return
            return // pendiente modal lateral
            this.$emit('modalResponsive')
        },
        redirect(routeName){
            this.$refs.popoverProfile.doToggle();

            switch(routeName){
            case "convenio":
            case "leecheros":
                this.$emit('listenerAction', routeName);
                break;
            default:
                this.$router.push({name: routeName});
                break;
            }
        },
        modalCupones(){
            this.$emit('verCupones') 
            this.$refs.popoverProfile.doToggle()
        },
    }
}
</script>
<style lang="scss" scoped>
.circled-user{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
}
.btn-invita-gana{
    border-radius: 12px;
    height: 80px;
     background-image: radial-gradient(closest-corner at 73% 62%,#CDB1FF, #A880FF, #7D4AE8);
}
</style>