import axios from 'axios';

const API = 'invitado';

const ENDPOINTS = {
    getData(){
        return axios.get(`${API}/paises-ciudades`)
    },
    getTienda(idCiudad = null){
        return axios.get(`${API}/ciudad/${idCiudad}/tienda`)
    },
    getCategorias(idTienda){
        return axios.get(`${API}/${idTienda}/categorias`)
    },
    getProductosCategoria(idCategoria,params){
        return axios.get(`${API}/${idCategoria}/categorias-productos`,{params})
    },
    getBannersPromocion(params){
        return axios.get(`${API}/promociones`,{params})
    }, 
    getPromocion(idPromo, params){
        return axios.get(`${API}/${idPromo}/promocion`,{params})
    },
    getProductosPromocion(id, params){
        return axios.get(`${API}/${id}/promocion-productos`,{params})
    },
    getProductosCarrito(params){
        return axios.get(`${API}/productos-carrito`,{params})
    },
    searchProducts(params){
        return axios.get(`${API}/productos/search`, {params})
    },
    getSubcategorias(idCategoria, params){
        return axios.get(`${API}/categoria/${idCategoria}/sub-categorias`, {params})
    }
};

export default ENDPOINTS;