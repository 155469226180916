<template>
    <section>
        <div v-if="producto != null" class="card-carrito d-flex mb-3 p-2 bg-white">
            <img :src="producto.imagen" width="90" height="90" class="obj-cover" />
            <div class="col px-2 text-general pt-2">
                <div class="row mx-0 align-items-center">
                    <div class="col px-0">
                        <p class="f-12 f-400 nombre-producto"> {{ producto.nombre }} </p>
                    </div>
                    <div class="bg-white circled-heart d-flex ml-auto rounded-circle a-center favorite-button j-center" @click="agregarFavorito">
                        <i :class="`${favorito ? 'icon-heart text-red' : 'icon-heart-outline text-gris2'} cr-pointer`" />
                    </div>
                </div>
                <p class="text-general2 f-12">{{ producto.presentacion }}</p>
                <div v-if="producto.promocion" class="row mx-0 f-14">
                    <p class="col-auto pl-0 pr-1 text-general f-600"> {{ convertMoney(funValor(producto.data_promocion.promo_valor)) }} </p>
                    <p class="col-auto px-2 text-gris f-600 descuento">
                        {{ convertMoney(funValor(producto.precio)) }}
                    </p>
                </div>
                <div v-else class="row mx-0 f-14">
                    <p class="col-auto pl-0 pr-1 text-general f-600"> {{ convertMoney(funValor(producto.precio)) }} </p>
                </div>
                <div v-if="producto.cantidadProducto" class="row mx-0 mt-2">
                    <div class="d-middle button-cant">
                        <div class="minus" @click="restarCantidad(producto.cantidadProducto)">
                            <i class="icon-minus f-12" />
                        </div>  
                        <div class="space-cant d-middle-center f-12">
                            {{ formatNumero(producto.cantidadProducto, decimales = 0, simbolo=false ) }}
                        </div>
                        <div class="minus m-1" @click="sumarCantidad(producto.cantidadProducto)">
                            <i class="icon-plus f-12" />
                        </div>
                        <div class="delete ml-auto" @click="eliminarProducto">
                            <i class="icon-trash-outline f-12" />
                        </div>
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div v-if="producto.promocion" class="pill-descuento f-12 px-2">
                        {{ producto.data_promocion.texto}}
                    </div>
                    <div v-show="$usuario.tipo_impuesto === 1 && producto.porcentaje_impuesto > 0" class="pill f-12 px-2 ml-1">
                        + IVA
                    </div>
                    <div v-show="$usuario.tipo_impuesto === 2 && producto.porcentaje_impuesto > 0" class="pill f-12 px-2 ml-1">
                        IVA incluido
                    </div>
                </div>
            </div>
            <!-- Partials -->
            <!-- <modal-producto ref="modalProducto" /> -->
        </div>
    </section>
</template>

<script>
export default {
    props: {
        producto: {
            type: Object,
            default: () => {}
        },
    },
    data(){
        return {
            url_producto: 'https://www.las2orillas.co/wp-content/uploads/2021/04/biscolata-600x600.jpeg',
            favorito: false,
            cant_producto: 0,
            cantidadBase: null
        }
    },
    computed: {
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
    },
    methods: {
        agregarFavorito(){
            this.favorito = !this.favorito;
        },
        accionCard(){
            if(this.infoProduct){
                this.$refs.modalProducto.toggle();
            }
        },
        eliminarProducto(){
            this.$emit("eliminarPedido", this.producto.id_producto)
        },
        sumarCantidad(item){
            if(this.cantidadBase == null){
                this.cantidadBase = item
            }
            this.producto.cantidadProducto += this.cantidadBase
        },
        restarCantidad(item){
            if(this.cantidadBase == null){
                this.cantidadBase = item
            }
            if(this.producto.cantidadProducto > this.cantidadBase){
                this.producto.cantidadProducto -= this.cantidadBase
            }
        },
        funValor(value){
            if(this.$usuario.tipo_impuesto === 0){
                return value
            }
            if(this.$usuario.tipo_impuesto === 1){
                return value / (1 + this.impuesto / 100)
            }
            if(this.$usuario.tipo_impuesto === 2 || this.$usuario.tipo_impuesto === 3){
                return value
            }
            return 0
        },
    }
}
</script>
<style lang="scss" scoped>
.card-carrito{
    border-radius: 8px;
    height: 170px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #0000000D;
    .nombre-producto{
      height: 38px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: pre-line;
    }
    .favorite-button{
        width:24px;
        height:24px;
    }
    .button-cant{
        .minus{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: var(--color-general);
            border: 1px solid var(--color-general);
        }
        .space-cant{
            width: 50px;
            color: #000000;
        }
        .plus{
            width: 24px;
            height: 24px;
            color: #FFFFFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: var(--color-general);
        }
    }
    .delete{
        width: 24px;
        cursor: pointer;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF1720;
        border: 1px solid #FF1720;
    }
    .pill{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
        background-color: #000000;
    }
    .pill-descuento{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
        background-color: var(--color-general);
    }
}
</style>