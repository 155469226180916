import axios from 'axios'
import Cookies from 'js-cookie'
import Servicio from '~/services/auth'
// state
export const state = {
    //user: null,
    token: Cookies.get('token'),
    usuario: {},
    error: {},
    usuarioRegistro: {},
}
// getters
export const getters = {
    obtenerUsuario: ({ usuario })  => usuario,
    obtenerToken: ({ token }) => token,
    obtenerError: ({ error }) => error,
    existeToken: ({ token }) => !_.isEmpty(token),
    existeUsuario: ({ usuario }) => !_.isEmpty(usuario) && !!usuario.id,
    obtenerDatosUsuarioRegistro: ({ usuarioRegistro }) => usuarioRegistro,
}
// mutations
export const mutations = {
    // --- Inicio Autenticación ---
    ALMACENAR_ERROR: (state, { response: { data } } ) => {
        state.error = {
            ...data,
            tipo: data.codigo ? 400 : 500
        }
    },
    REMOVER_ERRORES: state => {
        state.error = {}
    },
    ALMACENAR_TOKEN: (state, payload) => {
        state.token = payload
        Cookies.set('token', payload)
    },
    REMOVER_TOKEN: (state) => {
        state.token = null
        Cookies.remove('token')
    },
    ALMACENAR_USUARIO: (state, payload) => {
        state.usuario = payload
        if(payload.tienda){
            state.tienda = payload.tienda
        }
    },
    ALMANECAR_DATOS_USUARIO_REGISTRO: (state, payload) => {
        state.usuarioRegistro = payload
    },
    CERRAR_SESION: state => {
        localStorage.clear()
        Cookies.remove('token')
        state.usuario = {}
        state.token = '',
        state.error = {}
    },
}

// actions
export const actions = {
    // --- Inicio Autenticación ---
    async intentarIniciarSesion({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { token, usuario } } = await Servicio.iniciarSesion(payload)
        //
        commit('ALMACENAR_TOKEN', token)
        commit('ALMACENAR_USUARIO', usuario)
    },
    async intentarIniciarSesionSocial({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { token, usuario } } = await Servicio.iniciarSesionSocial(payload)
        //
        commit('ALMACENAR_TOKEN', token)
        commit('ALMACENAR_USUARIO', usuario)
    },
    async cerrarSesion({ commit }){
        const { data} = await Servicio.cerrarSesion()
        commit('CERRAR_SESION')
        commit('REMOVER_ERRORES')
    },
    async enviarCodigoCorreo({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { respuesta } } = await Servicio.enviarCodigoCorreo(payload)
        //
        commit('DATA_VERIFICAR_CODIGO', respuesta)
    },
    async consultarUsuario({ commit }){
        try{
            const { data } = await Servicio.getUsuario()
            if(!data.valid){
                commit('REMOVER_TOKEN')
            }
            commit('ALMACENAR_USUARIO', data.usuario)
        }catch(e){
            commit('REMOVER_TOKEN')
        }
    },
    async iniciarSesionGoogle({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { token, usuario } } = await Servicio.iniciarSesionGoogle(payload)
        commit('ALMACENAR_TOKEN', token)
        commit('ALMACENAR_USUARIO', usuario)
    }
    // --- Fin Autenticación ---
}
