import store from '~/store'

const config = store.getters['x_parametros/configuracion']
const menu = config.menu

const rutasGaming = [
    'recompensas',
    'recompensas.misiones.mes',
    'recompensas.misiones.evento',
    'premios',
    'premios.detalle',
    'premios.canjear',
    'mis-canjes',
    'mis-canjes.detalle',
    'invita-gana',
];

const rutasNomina = [
    'convenio', 
];

const rutasCredito = [
    'creditos', 
    'creditos.deudas.ver', 
];

export default async(to, from, next) => {

    //validar permiso a Gaming
    if(rutasGaming.includes(to.name)){
        if(menu.gamig){
            return next()
        }else{
            return next({name:'home'})
        }
    }

    //validar permiso a Nomina
    if(rutasNomina.includes(to.name)){
        if(menu.nomina){
            return next()
        }else{
            return next({name:'home'})
        }
    }

    //validar permiso a Creditos
    if(rutasCredito.includes(to.name)){
        if(menu.creditos){
            return next()
        }else{
            return next({name:'home'})
        }
    }

    return next()
}
