export default [
    {
        path: '/carrito-compras',
        name: 'carrito-compras',
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/carrito').then(m => m.default || m),
        meta: { gtm: 'Carrito' },
    },
    {
        path: '/checkout',
        name: 'carrito-compras.checkout',
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/carrito/checkout').then(m => m.default || m),
        meta: { gtm: 'CheckOut' },
    },
    {
        path: '/pedido-creado',
        name: 'carrito-compras.pedido-creado',
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/carrito/pedidoCreado').then(m => m.default || m),
    },
]
