<template>
    <el-drawer :visible.sync="drawer" :with-header="false" :size="`${ancho}%`" :direction="direccion">
        <div v-if="!noTitulo" class="row mx-0 a-center pr-2 border-bottom mb-2 bg-black text-white" style="height:48px;">
            <div class="col-auto px-3">
                <i class="icon-close f-12 cr-pointer" @click="cerrarDrawer" />
            </div>
            <div class="col">
                <p class="f-16 text-left text-center">
                    {{ titulo }}
                </p>
            </div>
        </div>
        <slot />
    </el-drawer>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        ancho: {
            type: Number,
            default: 33
        },
        direccion: {
            type: String,
            default: 'rtl'
        },
        noTitulo: {
            type: Boolean,
            default: false  
        }
    },
    data(){
        return {
            drawer: false,
        };
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
        },
        cerrarDrawer(){

            this.drawer = !this.drawer
        }
    }
}
</script>
