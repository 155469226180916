export default [
    {
        path: '/pedidos',
        name: 'pedidos',
        meta: { nombre: 'Pedidos' },
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/pedidos').then(m => m.default || m),
        meta: { gtm: 'Pedidos' },
    },
    {
        path: '/pedidos/recurrentes',
        name: 'pedidos.recurrentes',
        meta: { nombre: 'Pedidos' },
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/pedidos/pedidosRecurrentes').then(m => m.default || m),
        meta: { gtm: 'Pedidos' },
    },
    {
        path: '/pedidos/recurrentes/:id',
        name: 'pedidos.recurrentes.responsive',
        meta: { nombre: 'Pedidos' },
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/pedidos/components/productosRecurrentes').then(m => m.default || m),
        meta: { gtm: 'Pedidos' },
    },
    {
        path: '/pedidos/:idPedido/detalle',
        name: 'pedidos.ver',
        meta: { nombre: 'Pedidos' },
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/pedidos/pedidoVer').then(m => m.default || m),
        meta: { gtm: 'Pedidos' },
    },
    {
        path: '/pedidos/productos-con-cambios/:idPedido',
        name: 'pedidos.productos-cambios',
        meta: { nombre: 'Pedidos' },
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/pedidos/productosCambios').then(m => m.default || m),
        meta: { gtm: 'Pedidos' },
    },
]
