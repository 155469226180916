<template>
    <div class="card-producto bg-white px-2 py-2 br-12 cr-pointer mx-0 j-center mx-2 pb-2 mb-1 position-relative" :class="funBorde()">
        <div class="row mx-0 j-center mb-2" @click="verProducto">
            <img :src="producto.imagen ? producto.imagen : '/img/no-imagenes/mercado.svg'" class="obj-cover" style="height:190px;width:160px;" />
        </div>
        <div class="bg-white circled-heart d-flex rounded-circle a-center j-center position-absolute">
            <i :class="`${producto.favorito ? 'icon-heart text-red' : 'icon-heart-outline text-gris2'} cr-pointer`" @click="updateFavoritos" />
        </div>
        <div class="row mx-0 mb-2 j-center" @click="verProducto">
            <span v-if="producto.promocion" class="text-general f-12 f-600">{{ convertMoney(funValor(totalFinal)) }}</span>
            <span v-else class="text-general f-13 f-600">{{ convertMoney(funValor(totalTeorico)) }}</span>
            <span v-show="producto.promocion" class="text-gris2 ml-2 descuento f-12">{{ convertMoney(funValor(totalTeorico)) }}</span>
        </div>
        <div v-if="producto.promocion" class="row mx-0 j-center mb-2" @click="verProducto">
            <span class="bg-general br-10 text-white px-2 f-12">{{ promocion.texto }}</span>
        </div>
        <div class="row mx-0 mb-2 j-center" @click="verProducto">
            <p v-show="$usuario.tipo_impuesto === 1 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-12 text-center px-1">+ IVA</p>
            <p v-show="$usuario.tipo_impuesto === 2 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-12 text-center px-1">IVA incluido</p>
        </div>
        <div class="row mx-0 name-product" @click="verProducto">
            <p class="col-12 px-0 text-general nombre-producto f-14">{{ producto.nombre }}</p>
            <p class="col-12 px-0 f-12 text-general2 mt-2">{{ producto.presentacion }}</p>
        </div> 
        
        <div class="row mx-0 mt-2">
            <div class="col-12 px-0 f-15">
                <div v-if="producto.cant_carrito <= 0" class="button-card d-middle-center br-50" @click="agregarProductoCarrito">
                    Añadir
                    <i class="icon-cart-outline f-18" />
                </div>
                <div v-else class="d-middle button-cant">
                    <i class="icon-trash-can-outline f-16 text-danger border br-5 border-danger mr-1" @click="eliminarProductoCarrito" />
                    <el-input-number
                    v-model="producto.cant_carrito"
                    :min="parseFloat(producto.compra_minima)"
                    :max="maximaCompra"
                    :step="parseFloat(producto.cantidad_minima)"
                    :step-strictly="entero"
                    :precision="precision"
                    size="small" class="text-cent"
                    style="width:170px;"
                    @change="cambiarCantidad($event)"
                    />
                </div>
            </div>
        </div>
        <modal-producto 
        ref="modalProducto" 
        :producto="producto" 
        :promocion="promocion" 
        @eliminar="eliminarProductoCarrito"
        @agregar="agregarProductoCarrito"
        @update="cambiarCantidad"
        />
        <modal ref="modalAlertaEdad" titulo="" cancelar="Cancelar" :adicional="nombreBtn" @adicional="aceptarModal">
            <div v-if="mostrarEdad == 1" class="row mx-0 j-center">
                <p class="text-center">
                    ¿Eres mayor de edad?
                </p>
            </div>
            <div v-else class="row mx-0 j-center">
                <p class="f-600 my-3">
                    Dinos tu fecha de nacimiento
                </p>
                <div class="col-10">
                    <p class="text-general f-12 pl-3">Fecha de nacimiento</p>
                    <el-date-picker
                    v-model="fechaNac"
                    class="w-100"
                    type="date"
                    placeholder="Seleccionar"
                    />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import Carrito from '~/services/carrito/carrito'
import Favoritos from '~/services/favoritos/favoritos'
import Cliente from '~/services/cliente/cliente'
import moment from 'moment'
export default {
    props: {
        producto:{
            type:Object,
            default(){return {}}
            // id_producto:Number
            // cant_carrito:Number
            // nombre:String
            // presentacion:String
            // favorito:Boolean
            // imagen:String,
            // cantidad_tipo:Number
            // cantidad_minima: Number
            // compra_maxima:Number
            // compra_minima:Number
            // porcentaje_impuesto: null || Number
            // promocion:Boolean
            // Descripcion:String
            //Imagenes:Array
        },
        promocion:{
            type:Object,
            default(){return {}}
        }
    },
    data(){
        return {
            favorito: false,
            cant_product: 0,
            showCant: false,
            mostrarEdad: 1,
            nombreBtn: 'Si',
            fechaNac: '',
        }
    },
    computed: {
        entero(){
            if(this.producto.cantidad_tipo === 1){
                return true
            }else{
                return false
            }
        },
        precision(){
            if(this.producto.cantidad_tipo === 1){
                return 0
            }else{
                return 2
            }
        },
        impuesto(){
            const i = this.producto.porcentaje_impuesto
            return i ? i : 0
        },
        maximaCompra(){
            if(this.producto.compra_maxima === 0){
                return 999999
            }
            if(this.producto.cant_carrito > this.producto.compra_maxima){
                return parseFloat(this.producto.cant_carrito)
            }
            return parseFloat(this.producto.compra_maxima)
        },
        totalTeorico(){
            if(this.producto.cant_carrito == 0 || this.producto.cant_carrito == null){
                return this.producto.precio
            }
            return this.producto.total_teorico_carrito
        },
        totalFinal(){
            if((this.producto.cant_carrito == 0 || this.producto.cant_carrito == null) && this.producto.promocion){
                return this.producto.data_promocion.promo_valor
            }

            if((this.producto.cant_carrito == 0 || this.producto.cant_carrito == null) && !this.producto.promocion){
                return this.producto.precio
            }

            if(this.producto.promocion){
                return this.producto.total_carrito
            }else{
                return this.producto.total_teorico_carrito
            }
        }
        
    },
    methods: {
        verProducto(){
            this.$refs.modalProducto.toggle();
        },
        anadirCantidad(){
            this.showCant = !this.showCant
            this.cant_product++
        },
        cambiarCantidad(cantidad){
            if(this.producto.compra_maxima != 0){
                if(cantidad > this.producto.compra_maxima){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto. Permitido '+this.producto.compra_maxima+' Unidades','warning');
                }
            } else {
                if(cantidad > 99999){
                    return this.notificacion('Alerta','Ha excedido la cantidad máxima de compra para este producto.','warning');
                }
            }
            const change = () => {
                this.updateCantidad(cantidad)
            }
            this.delay(change)
        },
        funValor(value){
            if(this.$usuario.tipo_impuesto === 0){
                return value
            }
            if(this.$usuario.tipo_impuesto === 1){
                return value / (1 + this.impuesto / 100)
            }
            if(this.$usuario.tipo_impuesto === 2 || this.$usuario.tipo_impuesto === 3){
                return value
            }
            return 0
        },
        async agregarProductoCarrito(){
            try {
                if (this.$usuario.edad < 18){
                    return this.abrirModalEdad();
                }
                const form = {idProducto:this.producto.id_producto}
                const {data} = await Carrito.addProducto(form)
                this.producto.cant_carrito = data.producto.cantidad
                this.producto.total_carrito = data.producto.total_final
                this.producto.total_teorico_carrito = data.producto.total_teorico
                this.notificacion('','Producto agregado al carrito','success')

                this.$usuario.cant_productos_carrito = data.producto.productos_en_carrito;
            } catch (error){
                this.errorCatch(error)
            }
        },
        async updateCantidad(cantidad){
            try {
                const form = {
                    idProducto:this.producto.id_producto,
                    cantidad:cantidad
                }
                const {data} = await Carrito.updateCantidad(form)
                this.producto.total_carrito = data.producto.total_final
                this.producto.total_teorico_carrito = data.producto.total_teorico
               
            } catch (error){
                this.errorCatch(error)
            }
        },
        async eliminarProductoCarrito(){
            try {
                const {data} = await Carrito.eliminarProductoCarrito(this.producto.id_producto)
                this.producto.cant_carrito = 0
                this.notificacion('Eliminado','Producto eliminado del carrito','success')

                this.$usuario.cant_productos_carrito = data.productos_en_carrito;
               
            } catch (error){
                this.errorCatch(error)
            }
        },
        async updateFavoritos(){
            try {

                if(this.producto.favorito){
                    const {data} = await Favoritos.deleteFavorito(this.producto.id_producto)
                    this.producto.favorito = 0
                    this.notificacion('Eliminado','Producto eliminado de los favoritos','success')
                }else{
                    const {data} = await Favoritos.addFavorito(this.producto.id_producto)
                    this.producto.favorito = 1
                    this.notificacion('','Producto agregado a favoritos','success')
                }
               
            } catch (error){
                this.errorCatch(error)
            }
        },
        abrirModalEdad(){
            this.mostrarEdad = 1
            this.nombreBtn = 'Si'
            this.fechaNac = ''
            this.$refs.modalAlertaEdad.toggle();                    
        },
        aceptarModal(){
            if (this.nombreBtn == 'Si'){
                this.nombreBtn = 'Aceptar'
                this.mostrarEdad = 2
                this.fechaNac = ''
            }else if(this.nombreBtn == 'Aceptar'){
                if (this.fechaNac == '' || this.fechaNac == null){
                    this.notificacion('Advertencia','Por favor seleccione la fecha de nacimiento','warning')
                    return
                }
                this.edadAprovada()
                this.$refs.modalAlertaEdad.toggle();
            }
        },
        async edadAprovada(){
            await Cliente.putFechaNac({fecha:moment(this.fechaNac).format('YYYY-MM-DD'), idUserCliente: this.$usuario.id});            
            this.notificacion('Mensaje','Edad actualizada con correctamente','warning') 
            this.$store.dispatch('auth/consultarUsuario')
        },
        funBorde(){
            if(this.producto.promocion){
                return this.promocion.borde ? 'border-general-edit' : ''
            }
            return ''
        }
        
    }
}
</script>
<style lang="scss" scoped>
.card-producto{
    width: 240px;
    min-height: 228px;
    box-shadow: 0px 3px 6px #0000000D;

    &.full-width-card{
        width: 100% !important;
    }

    .circled-heart{
        width:24px;
        height:24px;
        top:10px;
        right:16px;
        box-shadow: 0px 2px 6px #0000000D;
    }
    .nombre-producto{
        height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }
    .btn-add{
        height: 32px;
    }
    .button-card{
        height: 30px;
        color: #FFFFFF;
        background-color: var(--color-general);
        font-size: 14px;;
        border-radius: 4px;
    }
    .button-cant{
        .minus{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: var(--color-general);
            border: 1px solid var(--color-general);
        }
        .space-cant{
            color: #000000;
        }
        .plus{
            width: 24px;
            height: 24px;
            color: #FFFFFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: var(--color-general);
        }
    }
}
</style>
