import axios from 'axios';

const API = 'cliente';

const ENDPOINTS = {
	getDatosCliente() {
		return axios.get(`${API}/datos`)
	},
	editCliente(payload) {
		return axios.put(`${API}/edit`, payload)
	},
	newPassword(payload) {
		return axios.put(`${API}/password-create`, payload)
	},
	editAvatar(payload) {
		return axios.put(`${API}/avatar/edit`, payload)
	},
	putFechaNac(payload) {
		return axios.put(`${API}/edit-fecha-nacimiento`, payload)
	},
	asignarTiendas() {
		return axios.put(`${API}/asignar-tiendas`)
	},
	updateTelefono(payload) {
		return axios.put(`${API}/update-telefono`, payload)
	},
	vincularSocial(payload) {
		return axios.put(`${API}/vincular-google`, payload)
	},
};

export default ENDPOINTS;