<template>
    <section>
        <div class="card-carrito d-flex mb-3 p-2 bg-white">
            <img :src="producto.foto" width="90" height="90" class="obj-cover" />
            <div class="col px-2 text-general pt-2">
                <div class="row mx-0 align-items-center">
                    <p class="f-12 f-400">{{ producto.nombre }}</p>
                    <div class="bg-white circled-heart d-flex ml-auto rounded-circle a-center favorite-button j-center" @click="agregarFavorito">
                        <i :class="`${favorito ? 'icon-heart text-red' : 'icon-heart-outline text-gris2'} cr-pointer`" />
                    </div>
                </div>
                <p class="text-general2 f-12">{{ producto.presentacion }}</p>
                <div class="row mx-0 f-14">
                    <p class="col-auto pl-0 pr-1 text-general f-600"> {{ producto.promocion ? formatNumero(producto.total_final, 0, true) : formatNumero(producto.valor_por_unidades, 0, true) }}</p>
                    <p v-if="producto.promocion" class="col-auto px-2 text-gris f-600 descuento">
                        {{ formatNumero(producto.valor_por_unidades, 0, true) }}
                    </p>
                </div>
                <div class="row mx-0 mt-2">
                    <div class="d-middle button-cant">
                        <div class="minus" @click="cant_producto -= 1">
                            <i class="icon-minus f-12" />
                        </div>
                        <div class="space-cant d-middle-center f-12">
                            {{ cant_producto }}
                        </div>
                        <div class="plus" @click="cant_producto += 1">
                            <i class="icon-plus f-12" />
                        </div>
                    </div>
                    <div class="delete ml-auto" @click="eliminarProducto">
                        <el-tooltip content="Eliminar" placement="bottom" effect="light">
                            <i class="icon-trash-outline f-12" />
                        </el-tooltip>
                    </div>
                </div>
                <div class="row mx-0 mt-2">
                    <div v-if="producto.promocion" class="pill-descuento f-12 px-2">
                        {{ producto.data_promocion.texto }}
                    </div>
                </div>
            </div>
            <!-- Partials -->
            <!-- <modal-producto ref="modalProducto" /> -->
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: {
        producto: {
            type: Object,
            default: () => {}
        },
    },
    data(){
        return {
            favorito: false,
            cant_producto: this.producto.unidades_pedidas
        }
    },
    computed: {
        ...mapGetters({
            carrito: 'invitado/carrito',
            favoritos: 'invitado/favoritos'
        }),
    },
    watch:{
        cant_producto(){
            this.cambioCantidadProducto();
        },
    },
    mounted(){
        this.esFavorito();
    },
    methods: {
        cambioCantidadProducto(){
            let carrito = JSON.parse(localStorage.getItem('carritoInvitado'));
            let indexLS = carrito.findIndex((item) => item.id_producto === this.producto.id_producto); // Index local storage
            let indexG = this.carrito.findIndex((item) => item.id_producto === this.producto.id_producto); // Index getter
            if(this.cant_producto < 1){
                return this.removerProductoCarrito(carrito, indexLS, indexG);
            }
            this.actualizarCantidadProducto(carrito, indexLS, indexG);
        },

        async actualizarCantidadProducto(carrito, indexLS, indexG){
            carrito[indexLS].cantidad = this.cant_producto;
            this.carrito[indexG].cantidad = this.cant_producto;
            const asignar = JSON.stringify(carrito);
            localStorage.setItem('carritoInvitado', asignar);
            this.$emit('resumen');
        },

        async removerProductoCarrito(carrito, indexLS, indexG){
            carrito.splice(indexLS, 1);
            this.carrito.splice(indexG, 1);
            const asignar = JSON.stringify(carrito);
            localStorage.setItem('carritoInvitado', asignar);
            let carritoVacio = JSON.parse(localStorage.getItem('carritoInvitado'));
            if(carritoVacio.length < 1){
                localStorage.removeItem('carritoInvitado');
                return this.$emit('remover');
            }
            this.$emit('actualizar');
        },

        async eliminarProducto(){
            let carrito = JSON.parse(localStorage.getItem('carritoInvitado'));
            let indexLS = carrito.findIndex((item) => item.id_producto === this.producto.id_producto); // Index local storage
            let indexG = this.carrito.findIndex((item) => item.id_producto === this.producto.id_producto); // Index getter
            this.removerProductoCarrito(carrito, indexLS, indexG);
        },
        esFavorito(){
            let esFavorito = this.favoritos.find((item) => item.id_producto === this.producto.id_producto);
            if (esFavorito){
                this.favorito = !this.favorito;
            }
        },
        agregarFavorito(){
            this.favorito = !this.favorito;
            let productosFavoritos = this.favoritos;
            if(this.favorito){
                // Añadimos el producto a favoritos
                let data = { id_producto: this.producto.id_producto };
                productosFavoritos.push(data);
                const asignar = JSON.stringify(productosFavoritos);
                localStorage.setItem('favoritosInvitado', asignar);
                this.$store.commit('invitado/obtenerProductosFavoritos', productosFavoritos);
            }else{
                // Quitamos el producto de favoritos
                let index = productosFavoritos.findIndex(e => e.id_producto === this.producto.id_producto);
                productosFavoritos.splice(index, 1);
                const asignar = JSON.stringify(productosFavoritos);
                localStorage.setItem('favoritosInvitado', asignar);
                this.$store.commit('invitado/obtenerProductosFavoritos', productosFavoritos);
            }
        },

        accionCard(){
            if(this.infoProduct){
                this.$refs.modalProducto.toggle();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.card-carrito{
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #0000000D;
    .favorite-button{
        width:24px;
        height:24px;
    }
    .button-cant{
        .minus{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: var(--color-general);
            border: 1px solid var(--color-general);
        }
        .space-cant{
            width: 50px;
            color: #000000;
        }
        .plus{
            width: 24px;
            height: 24px;
            color: #FFFFFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: var(--color-general);
        }
    }
    .delete{
        width: 24px;
        cursor: pointer;
        height: 24px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF1720;
        border: 1px solid #FF1720;
    }
    .pill{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
        background-color: #000000;
    }
    .pill-descuento{
        min-width: 40px;
        height: 18px;
        color: #FFFFFF;
        text-align: center;
        border-radius: 20px;
        background-color: var(--color-general);
    }
}
</style>