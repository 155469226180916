<template>
    <div
    ref="modalgbp"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    >
        <div
        class="modal-dialog modal-dialog-centered"
        :class="tamano ? tamano : ''"
        role="document"
        >
            <div class="modal-content">
                <div class="modal-header mb-1 position-relative">
                    <div class="col-auto f-500">
                        {{ titulo }}
                    </div>
                    <div class="ml-auto my-auto d-middle-center bg-white rounded-circle" style="width:20px;height:20px;">
                        <i class="icon-cancel-circled-outline f-20 cr-pointer text-gr-general" data-dismiss="modal" />
                    </div>
                </div>
                <div class="modal-body overflow-auto custom-scroll" style="max-height:70vh;">
                    <slot />
                </div>
                <div class="row mx-0 j-center py-2">
                    <div v-if="showLine" class="col-12 px-0 mx-0 bg-linea mt-2 mb-2" />
                    <button
                    v-if="!noCancelar"
                    type="button"
                    class="btn-cancelar mx-2"
                    @click="actionCancelar"
                    v-text="cancelar"
                    />

                    <button
                    v-if="!noAdicional"
                    v-loading="cargando"
                    :disabled="desactivarAdicional"
                    type="button"
                    class="btn-adicional d-flex a-center j-center mx-2"
                    @click="actionAdicional"
                    v-text="adicional"
                    />
                    <slot name="buttonSpecific" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        showLine: {
            type: Boolean,
            default: true
        },
        tamano: {
            type: String,
            default: 'modal-md'
        },
        desactivarAdicional: {
            type: Boolean,
            default: false
        },
        noAdicional: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: 'Aceptar'
        },
        noCancelar: {
            type: Boolean,
            default: false
        },
        cancelar: {
            type: String,
            default: 'Cancelar'
        },
        cargando: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        toggle(){
            $(this.$refs.modalgbp).modal('toggle')
        },
        actionCancelar(){
            this.$emit('cancelar')
            this.toggle()
        },
        actionAdicional(){
            this.$emit('adicional')
        },
    }
}
</script>

<style lang="scss" scoped>
@import '~/sass/gbp.scss';

.modal-content{
    border: none;
    border-radius: 8px;
    .modal-header{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom: none;
        background-color: #F6F9FB;
        background-size: cover;

        .close{
            outline: none;
            transition-duration: .85s;
            position: absolute;
            right: 10px;
            top: 1px;
            font-size: 2.5em;

            &:hover{
                transition-duration: .4s;
                transform: rotate(360deg) scale(1.2);
            }
        }
        .logo{
            display: block;
            // height: 70px;
            // width: 70px;

            span{
                font-size: 1.2em;
            }
        }
    }
    .modal-title{
        color: $azul2-primary;
        font-weight: normal;
    }
}
.img-tipo{
    position: absolute;
    top:20px;
    left: 50%;
    transform: translate(-50%);
}
.bg-linea{
    background: linear-gradient(to right, #fff, #D1D1D1, #fff);
    height: 1px;
}
.bar-title{
    width: 44px;
    height: 5px;
    background-color: var(--color-general);
}

/* @media (min-width: 300px) and (max-width: 815px) {} */
</style>
