<template>
    <div v-if="infoPremio != null" class="card-premio bg-white px-2 py-2 br-12 cr-pointer mx-0 j-center mx-2 pb-2 mb-1 position-relative" :class="fullWidth ? 'full-width-card' : ''" @click="verPremio(infoPremio.id_producto)">
        <div class="row mx-0 j-center mb-2">
            <img :src="infoPremio.imagen" class="obj-cover" style="height:90px;width:90px;" />
        </div>
        <div class="row mx-0 j-center a-center mb-2">
            <img :src="`/img/ilustraciones/gaming/${tipoPago == false ? 'i_moneda' : 'i_gema'}.svg`" width="30" height="30" />
            <div v-if="tipoPago == false" class="col-auto px-1 text-general f-13 f-600 mt-1">
                {{ formatNumero(infoPremio.monedas) }} 
            </div>
            <div v-else class="col-auto px-1 text-general f-13 f-600 mt-1">
                {{ formatNumero(infoPremio.gemas) }}
            </div>
        </div>
        <div class="row mx-0">
            <p class="col-12 px-0 text-general descripcion-producto f-12"> {{ infoPremio.nombre }}</p>
        </div>
    </div>
    <div v-else class="card-premio bg-white px-2 py-2 br-12 cr-pointer mx-0 j-center mx-2 pb-2 mb-1 position-relative" :class="fullWidth ? 'full-width-card' : ''" @click="verCategoria">
        <div class="row mx-0 j-center mb-2">
            <i class="icon-arrow-left cr-pointer f-28 mt-3" style="height:50px;width:50px;" /> 
        </div>
        <div class="row mx-0 j-center a-center mb-2">
            <div class="col-auto px-1 text-center f-19 mt-1 ">
                Vér más productos 
            </div>
        </div>
        <div class="row mx-0">
            <p class="col-12 px-0 f-13 f-600 text-center descripcion-producto f-15"> {{ contador }}</p>
        </div>
    </div>    
</template>

<script>
export default {
    props: {
        tipoPago: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        infoPremio: {
            type: Object,
            default: null,
        },
        contador: {
            type: Number,
            default: 0,
        },
        idCategoria: {
            type: Number,
            default: 0,
        },
    },
    data(){
        return {
        }
    },
    methods: {  
        verPremio(item){
            this.$router.push({name: 'premios.detalle', params: { id_premio: item } });
        },
        verCategoria(){
            this.$emit("verCategoriaId", this.idCategoria);  
        },
    }
}
</script>
<style lang="scss" scoped>
.card-premio{
    width: 146px;
    min-height: 204px;
    box-shadow: 0px 3px 6px #0000000D;

    &.full-width-card{
        width: 100% !important;
    }

    .descripcion-producto{
        height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }

    .icon-arrow-left{
  transform: rotate(180deg);
}
    
}
</style>


