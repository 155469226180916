<template>
    <div class="main-layout">
        <navbar-landing />
        <div class="app-wrapper">
            <child />
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
export default {
    name: 'auth',
}
</script>
<style lang="scss" scoped>
main{
    padding-left: 65px !important
}
.app-wrapper {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    background: #F6F9FB;
    color: #000000;
    height: calc(100vh - 80px);
    width: 100vw;
    box-shadow: 0 3px 6px 0 #00000029;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}

@media (min-width: 300px) and (max-width: 1024px){
    .app-wrapper{
        height: calc(100vh - 96px) !important;
    }
}

</style>
