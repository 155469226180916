import Servicio from "~/services/home/home";

export const state = {
    secciones: [],
    homeCache:false,
    footerCache:false,
    isInvitado:false,
    redes:{},
    viewMenuCategorias:false
};

export const getters = {
    secciones: state => state.secciones,
    redes: state => state.redes,
    homeCache: state => state.homeCache,
    footerCache: state => state.footerCache,
    isInvitado: state => state.isInvitado,
    viewMenuCategorias: state => state.viewMenuCategorias,
};

export const mutations = {
    setSecciones: (state, payload) => {
        state.homeCache = true
        state.secciones = payload;
    }, 
    setHomeCache:(state, payload) => {
        state.homeCache = payload
    }, 
    setFooterCache:(state, payload) => {
        state.footerCache = payload
    }, 
    setInvitado:(state, payload) => {
        state.isInvitado = payload
    },
    setRedes: (state, payload) => {
        if(payload === null){
            const redes = {
                facebook:null,
                instagram:null,
                whatsapp:null
            }
            state.redes = redes
            return
        }
        state.redes = payload;
    },
    setMenuCategorias:(state, payload) => {
        state.viewMenuCategorias = payload
    }
    
};

export const actions = {
    async getHome({ commit }, payload){
        const { data } = await Servicio.getHome(payload);
        commit("setSecciones", data.secciones);
        commit("setRedes", data.redes);
        
        return {tienda:data.tiendaDefault}
    },
    async guardarClick({ commit,state }, idElemento){
        if(state.isInvitado) return
        const { data } = await Servicio.guardarClick(idElemento);
    },
    async guardarVisita({ commit,state }){

        if(state.isInvitado) return
        const { data } = await Servicio.guardarVisita();
    }, 
    async syncCarrito({ commit,state }, payload){

        //if(state.isInvitado) return
        const { data } = await Servicio.sincronizarCarrito(payload);
    },
};
