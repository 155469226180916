export default [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/index').then(m => m.default || m),
    },

    {
        path: '/registro',
        name: 'registro',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/registro').then(m => m.default || m),
    },
    {
        path: '/ingreso',
        name: 'ingreso',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/ingreso').then(m => m.default || m),
    },
    {
        path: '/ingreso-celular',
        name: 'ingreso.celular',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/ingresoCelular').then(m => m.default || m),
    },
    {
        path: '/verificar-codigo-ingreso',
        name: 'verificar.codigo.ingreso',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/verificarCodigoIngreso').then(m => m.default || m),
    },
    {
        path: '/ingreso-correo',
        name: 'ingreso.correo',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/ingresoCorreo').then(m => m.default || m),
    },
    {
        path: '/recuperar-pass',
        name: 'recuperar-password',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/recuperarContrasena').then(m => m.default || m),
    },
    {
        path: '/verificar-codigo-recuperar',
        name: 'verificar-codigo',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/verificarCodigo').then(m => m.default || m),
    },
    {
        path: '/nueva-pass',
        name: 'nueva-pass',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/nuevaPass').then(m => m.default || m),
    },
    {
        path: '/verificar-codigo-registro',
        name: 'verificar-codigo-registro',
        component: () => import(/* webpackChunkName: "login" */ '../../pages/login/verificarCodigoRegistro').then(m => m.default || m),
    },
]
