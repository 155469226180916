import axios from 'axios';

const API = 'auth';

const ENDPOINTS = {
    iniciarSesion(payload){
        return axios.post(`${API}/login`, payload)
    },
    iniciarSesionSocial(payload){
        return axios.post(`${API}/login-social`, payload)
    },
    iniciarSesionNumeroCelular(payload){
        return axios.post(`${API}/login-telefono`, payload)
    },
    cerrarSesion(payload){
        return axios.post(`${API}/logout`, payload)
    },
    enviarCodigoCorreo(payload){
        return axios.post(`${API}/enviar-codigo-correo`, payload)
    },
    verificarCodigoInicioSesion(id,params){
        return axios.get(`${API}/${id}/verificar-codigo`, {params})
    },
    getUsuario(){
        return axios.get(`${API}/get-usuario`)
    },
    getVerificarToken(){
        return axios.get(`${API}/verificar-token`)
    },
    getAvatares(){
        return axios.get(`${API}/avatares`)
    },
    existeUsuario(params){
        return axios.get(`${API}/existe`, {params})
    },
    getPaises(){
        return axios.get(`${API}/paises`)
    },
    postRegistro(payload){
        return axios.post(`${API}/registro`, payload)
    },
    postNuevaContrasena(payload){
        return axios.post(`${API}/recuperar-password`, payload)
    },

    iniciarSesionGoogle(payload){
        return axios.post(`${API}/login-google`, payload)
    },
    getTipoIdentificacion(){
        return axios.get(`${API}/tipo-identificacion`)
    }
};

export default ENDPOINTS;