<template>
    <modal ref="modalDetalleProducto" titulo="Costillas de cerdo" product-url="https://d1.com.co/wp-content/uploads/2021/04/12000073.jpg" no-aceptar>
        <div class="row mx-0">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0 j-center my-1 mb-2">
                    <span class="text-general mr-2 f-500">$13.500</span>
                    <span class="text-gris">$15.500</span>
                </div>
                <div class="row mx-0 j-center my-2 mb-2">
                    <div class="bg-general text-white f-600 br-20 f-12 f-12 px-3">
                        DESCUENTO
                    </div>
                </div>
                <p class="text-general f-15 mb-2">Aporta una gran cantidad de vitaminas y prebióticos ideales para el cuidado de tu familia.</p>
                <p class="text-general f-15 mt-1">
                    <span class="f-500">Presentación:</span>
                    Kilogramo
                </p>
                <p class="text-general f-15 mt-1">
                    <span class="f-500">Unidad de medida:</span>
                    1 Kg.
                </p>
                <p class="text-general f-15 mt-1">
                    <span class="f-500">Venta mínima:</span>
                    0.5 Kilogramos
                </p>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return{

        }
    },
    methods: {
        toggle(){
            this.$refs.modalDetalleProducto.toggle();
        }
    }
}
</script>