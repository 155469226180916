export default [
    {
        path: '/cupones',
        name: 'cupones',
        component: () => import(/* webpackChunkName: "favoritos" */ '../../pages/cupones').then(m => m.default || m),
        meta: { gtm: 'Cupones' },
    },
    {
        path: '/detalle-cupon',
        name: 'cupon.detalle-cupon',
        component: () => import(/* webpackChunkName: "favoritos" */ '../../pages/cupones/detalleCupon').then(m => m.default || m),
        meta: { gtm: 'Cupones' },
    },
]
