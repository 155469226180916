<template>
    <section class="footer-page pt-5 px-xl-0 px-lg-0 px-md-0 px-sm-4 px-4">
        <div class="row mx-0 justify-content-xl-around justify-content-lg-around justify-content-md-center justify-content-sm-start justify-content-around">
            <div class="col-auto px-xl-4 px-lg-2 px-md-2 px-sm-1 px-1 d-xl-block d-lg-block d-md-none d-sm-none d-none" />
            <div class="col-xl-auto col-lg-auto col-md-4 col-sm-7 col-11 px-xl-2 px-lg-2 px-md-3 px-sm-3 px-0">
                <img src="/img/logo_blanco.svg" width="137" />
                <p class="f-15 f-300 mt-3">{{ $config.proyecto }}, 2021</p>
            </div>
            <div class="col-auto px-xl-4 px-lg-2 px-md-2 px-sm-1 px-1 d-xl-block d-lg-block d-md-none d-sm-none d-none" />
            <div class="col-xl-auto col-lg-auto col-md-4 col-sm-7 col-11 px-xl-2 px-lg-2 px-md-3 px-sm-3 px-0">
                <p class="f-15 f-300 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-3 mt-3" @click="$router.push({name: 'preguntas'})">
                    Preguntas frecuentes
                </p>
                <p class="f-15 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-2 mt-2 f-300">
                    Quiero ser {{ $config.vendedor }}
                </p>
            </div>
            <div class="col-auto px-xl-4 px-lg-2 px-md-2 px-sm-1 px-1 d-xl-block d-lg-block d-md-none d-sm-none d-none" />
            <div class="col-xl-auto col-lg-auto col-md-4 col-sm-7 col-11 px-xl-2 px-lg-2 px-md-3 px-sm-3 px-0">
                <p class="f-15 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-2 mt-2 f-300" @click="$router.push({name: 'terminos-condiciones'})">
                    Términos y condiciones
                </p>
                <p class="f-15 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-2 mt-2 f-300">
                    Política de privacidad
                </p>
            </div>
            <div class="col-auto px-xl-4 px-lg-2 px-md-2 px-sm-1 px-1 d-xl-block d-lg-block d-md-none d-sm-none d-none" />
            <div class="col-xl-auto col-lg-auto col-md-12 col-sm-12 col-12 mt-xl-0 mt-lg-0 mt-md-3 mt-sm-3 mt-3">
                <p class="f-15 text-xl-left text-lg-left text-md-left text-sm-left text-left f-300">
                    Siguenos en
                </p>
                <div class="row mx-0 mt-2 mb-5">
                    <div class="btn-redes mr-2 d-flex a-center j-center">
                        <i class="icon-facebook f-20" />
                    </div>
                    <div class="btn-redes mx-2 d-flex a-center j-center">
                        <i class="icon-instagram f-20" />
                    </div>
                    <div class="btn-redes mx-2 d-flex a-center j-center">
                        <i class="icon-whatsapp f-20" />
                    </div>
                    <div class="btn-redes mx-2 d-flex a-center j-center">
                        <i class="icon-youtube-play f-20" />
                    </div>
                    <div class="btn-redes mx-2 d-flex a-center j-center">
                        <i class="icon-linkedin f-20" />
                    </div>
                </div>
                <p class="contact-us f-15 f-300 text-right">
                    Contáctanos: hola@{{ $config.proyecto }}.co
                </p>
                <p class="contact-us f-15 f-300 text-right">
                    Estamos ubicados en Bucaramanga, Colombia
                </p>
            </div>
        </div>
    </section>    
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.footer-page{
    min-height: 274px;
    background-color: #000000;
    color: #FFFFFF;
    .btn-redes{
        width: 36px;
        background-color: #FFFFFF;
        height: 36px;
        border-radius: 50%;
        color: #000000;
    }
}

@media (min-width: 300px) and (max-width: 1048px) {
    .contact-us{
        display: none !important;
    }
}
</style>