<template>
    <footer v-if="footers.length" class="footer-main container-fluid px-4 py-2" :style="`background:${params.fondo};`">
        <div class="row">
            <div v-for="(item,index) in footers" :key="index" class="col-12 col-lg-3 mb-2">
                <p class="f-14 f-600 mb-2 ucfirst" :style="`color:${params.titulos}`">{{ item.texto }}</p>
                <p v-for="(data,idx) in item.items" :key="idx" class="f-12 my-1 ucfirst">
                    <a :href="data.ruta" :class="{'cr-pointer': data.ruta}" :style="`color:${params.textos} !important`" target="_blank">{{ data.texto }}</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import Service from '~/services/home/home'
export default{
    data(){
        return {
            background: '#000',
            titulos: '#fff',
            textos: '#fff',
            footers: [],
            params:{
                fondo:'#000',
                titulos: '#fff',
                textos: '#fff',

            }
        }
    },
    computed:{
        ...mapGetters({
            footerCache:'home/footerCache',
        })
    },
    created(){
        this.getFooter()
    },
    methods:{
        async getFooter(){
            try {
            
                //if(this.footerCache) return

                const {data} = await Service.getFooter()
                this.footers = data.secciones
                this.params = data.params
                
                this.$store.commit('home/setFooterCache',true)

            } catch (error){
                this.errorCatch(error)
            }
        }
    }
    
}
</script>

<style lang="scss" scoped>
.footer-main{
	z-index: 100 !important;
	position: relative;
	a{
		text-decoration: none !important;
	}
}
</style>