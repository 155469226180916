<template>
    <div :class="`btn-recompensa${small ? '-small' : ''}`">
        <div :class="`${showImage ? 'col-auto' : 'col px-2'}`">
            <p class="text-center f-13 pt-1">
                {{ texto }}
            </p>
            <div class="reward-coins d-middle">
                <div class="col-auto px-2 d-middle">
                    <img src="/img/ilustraciones/gaming/i_moneda.svg" width="25" height="25" />
                    <span class="mx-1 text-white f-500 f-16">{{ monedas }}</span>
                </div>
                <div class="col-auto px-2 d-middle">
                    <img src="/img/ilustraciones/gaming/i_gema.svg" width="25" height="25" />
                    <span class="mx-1 text-white f-500 f-16">{{ gemas }}</span>
                </div>
            </div>
        </div>
        <div v-if="showImage" class="col px-0">
            <img v-if="showImage" src="/img/ilustraciones/gaming/grupo_recompensas.png" class="group" width="120" height="120" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        showImage: {
            type: Boolean,
            default: false,
        },
        texto: {
            type: String,
            default: 'Tus recompensas'
        },
        small: {
            type: Boolean,
            default: false,
        },
        recompensasUser: {
            type: Object,
            default: null,
        },
        monedas: {
            type: Number,
            default: 0
        },
        gemas: {
            type: Number,
            default: 0
        },
    },
    data(){
        return {

        }
    }
}
</script>

<style lang="scss">
.btn-recompensa{
    width: 312px;
    height: 74px;
    color: #FFFFFF;
    display: flex;
    position: relative;
    border-radius: 12px;
    background: radial-gradient(circle at 100%, #F9C7FF, #9F4BFF , #612AD5);
    img.group{
        top: -20px;
        left: -25px;
        position: absolute;
        @media (min-width: 300px) and (max-width: 1024px){
            width: 100px;   
            height: 100px;
        }
    }
    &-small{
        @extend .btn-recompensa;
        width: auto;
        height: 54px;
        .reward-coins{
            height: 24px !important;
        }
    }
    .reward-coins{
        height: 36px;
        background-color: #ffffff7a;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 8px;
    }

}
</style>