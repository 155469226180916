<template>
    <section class="section-modal">
        <modal-web 
        ref="modalClienteLeeche" 
        :tamano="tamano" 
        :titulo="titulo" 
        :no-cancelar="noCancelar" 
        :no-adicional="noAdicional" 
        :adicional="adicional"
        :desactivar-adicional="desactivarAdicional"
        :cargando="cargando"
        :cancelar="cancelar"
        :show-line="showLinea"
        @adicional="adicionalModal"
        @cancelar="cerrarModal"
        >
            <slot />
        </modal-web>
        <!-- <modal-mobil 
        v-else 
        ref="modalClienteLeeche" 
        :titulo="titulo" 
        :no-cancelar="noCancelar" 
        :no-aceptar="noAceptar" 
        :adicional="adicional" 
        @aceptar="aceptarModal"
        @cerrar="cerrarModal"
        @adicional="adicionalModal"
        >
            <slot />
        </modal-mobil> -->
    </section>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        tamano: {
            type: String,
            default: 'modal-md'
        },
        noCancelar: {
            type: Boolean,
            default: false
        },
        noAdicional: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: 'Aceptar'
        },
        desactivarAdicional: {
            type: Boolean,
            default: false
        },
        cancelar: {
            type: String,
            default: 'Cancelar'
        },
        cargando: {
            type: Boolean,
            default: false
        },
        showLinea: {
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            widthWindow: 0
        }
    },
    created(){
        this.handleResize();
    },
    methods: {

        cerrarModal(){
            this.$refs.modalClienteLeeche.toggle();
            this.$emit('cancelar')
        },
        adicionalModal(){
            this.$emit('adicional')
        },
        toggle(){
            this.$refs.modalClienteLeeche.toggle();
        },
        handleResize(){
            this.widthWindow = window.innerWidth;
        }
    }
}
</script>
<style lang="scss" scoped>
.section-modal{
    position: absolute;
    top: 0;
    left: 0;
}
</style>