<template>
    <modal ref="modalRegistrarse" titulo="Registrate" no-adicional no-cancelar>
        <div class="row mx-0 j-center mb-3">
            <img :src="funImagenGeneral(41)" />
            <span class="text-general w-100 f-15 row mx-0 j-center">Registrate o inicia sesión para una mejor experiencia</span>
        </div>
        <div class="row justify-content-center">
            <div class="btn-iniciar-sesion text-white mr-3 cr-pointer br-12" @click="toggle(), $router.push({name: 'ingreso'})">
                Iniciar sesión
            </div>
            <div class="btn-secondary px-3 py-1 cr-pointer br-12" @click="toggle(), $router.push({name: 'registro'})">
                Registrarse
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            buscar: '',
            cuponActivo: null,
            cupones: [],
            detalle_cupon: null
        }
    },
    methods: {
        toggle(){
            this.$refs.modalRegistrarse.toggle();
        },
       
    }
}
</script>
<style lang="scss" scoped>
.btn-iniciar-sesion{
    box-shadow: 0px 3px 6px #00000029;
    width: 147px;
    height: 32px;
    border-radius: 8px;
    background-color: var(--color-general);
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>