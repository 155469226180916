<template>
    <modal
    ref="modalConfirmar"
    :titulo="titulo"
    :adicional="adicional"
    :cancelar="cancelar"
    @cancelar="toggle"
    @adicional="onAdicional"
    >
        <div class="container">
            <p class="my-3 f-16 justify-center text-center px-3">
                {{ mensaje }}
            </p>
            <slot />
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        mensaje: {
            type: String,
            default: ''
        },
        cancelar: {
            type: String,
            default: 'No'
        },
        adicional: {
            type: String,
            default: 'Sí'
        },
    },
    methods: {
        toggle(){
            this.$refs.modalConfirmar.toggle()
        },
        onCancelar(){
            this.$emit('cancelar')
            this.toggle()
        },
        onAdicional(){
            this.$emit('adicional')
        }
    }
}
</script>
