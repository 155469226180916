export default [
    {
        path: '/invitado/promocion/:idPromocion',
        name: 'invitado.promocion.ver',
        component: () => import('../../pages/invitado/promociones/promocionesVer').then(m => m.default || m),
        
    },
    {
        path: '/invitado/carrito',
        name: 'invitado.carrito',
        component: () => import('../../pages/invitado/carrito').then(m => m.default || m),
    },
    {
        path: '/invitado/busqueda',
        name: 'invitado.buscar.producto',
        component: () => import('../../pages/invitado/resultadosBusqueda').then(m => m.default || m),
    },
    {
        path: '/invitado/categoria/:idCategoria/ver',
        name: 'invitado.categoria.ver',
        component: () => import('../../pages/invitado/categorias/index.vue').then(m => m.default || m),
        children: [
            {
                path: '/invitado/categoria/:idCategoria/sub/:idSubCategoria',
                name: 'invitado.categoria.ver.subcategoria',
                component: () => import('../../pages/invitado/categorias/index').then(m => m.default || m),
            }
        ]
    }
]
