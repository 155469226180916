import axios from 'axios'

const ENDPOINTS = {
    getCategorias(){
        return axios.get(`categorias`)
    },
    getProductosCategorias(params){
        return axios.get(`categorias/productos`, {params})
    },
    getProductosCategoria(params){
        return axios.get(`categoria/productos`,{params})
    },
    // getProductosSubCategoria(params){
    //     return axios.get(`categoria/productos`,{params})
    // },
    getSubcategorias(idCategoria){
        return axios.get(`categoria/${idCategoria}/sub-categorias`)
    },
    getProductosKit(idProducto){
        return axios.get(`productos/${idProducto}/kit`)
    },
};

export default ENDPOINTS;