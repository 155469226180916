<template>
    <section>
        <p class="f-600 f-18 text-general mb-2">Productos del KIT</p>
        <div v-for="(k,key) in productosKits" :key="key" class="d-middle m-1">
            <img :src="k.imagen" class="obj-cover mr-2" width="50" height="50" />
            <div>
                <p class="f-500 f-13 text-general">{{ k.nombre }}</p>
                <p class="f-12">{{ k.presentacion }}</p>
            </div>
            <div class="ml-auto rounded-circle border px-2 text-white bg-black">
                {{ k.cantidad }}
            </div>
        </div>
    </section>
</template>

<script>
import Service from '~/services/categorias/categorias'
export default {
    data(){
        return {
            productosKits: []
        }
    },
    methods: {
        async getProductosKit(idProducto){
            try {
                const {data} = await Service.getProductosKit(idProducto)
                this.productosKits = data.kits
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
