<template>
    <div class="row mx-0 tabs overflow-auto custom-scroll ml-5">
        <div class="mb-1" style="display:inline-flex;">
            <template v-for="(item,index) in tabs">
                <router-link
                :key="index"
                :data-xd="item.ruta"
                :to="item.ruta"
                class="d-middle position-relative br-10 mr-2"
                >
                    <p class="nombre-tab">{{ item.nombre }}</p>
                </router-link>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TabsIcons',
    props: {
        tabs: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        isActive(){
            return this.$route.path
        }
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.tabs{
    a{
		white-space: nowrap;
        padding: 5px 12px 5px 12px !important;
        color: #7a7777;
        font-size: 14px;
        border: 1px solid;
        &.router-link-active{
            padding: 12px 12px 5px 12px;
            color: white !important;
            background: var(--color-general);
            .nombre-tab{
                display: inline-block;
            }
           
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
