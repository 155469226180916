<template>
    <div id="app">
        <loading ref="loading" />
        <transition name="page" mode="out-in">
            <component :is="layout" v-if="layout" />
        </transition>
    </div>
</template>

<script>
import Loading from './components/Loading'
import moment from "moment";

// Load layout components dynamically.
const requireContext = require.context('~/layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
        components[name] = component.default || component
        return components
    }, {})

export default {
    el: '#app',

    components: {
        Loading
    },
    data: () => ({
        layout: null,
        defaultLayout: 'default'
    }),
    watch:{
        $usuario(val){
            if(val.id_tienda){
                this.$store.commit('home/setInvitado',false)
            }else{
                this.$store.commit('home/setInvitado',true)
            }
        }
    },

    metaInfo(){
        const  appName  = this.$config.proyecto

        return {
            title: appName,
            titleTemplate: `${appName}`
        }
    },

    async mounted(){
        const protocol = window.location.protocol
        //console.log(protocol);
        const domain = window.location.host.substring(0,9)
        
        if(protocol !== "https:" && domain !== 'localhost'){
            window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }
        this.$loading = this.$refs.loading

        if(localStorage.configuracion){
            if(this.$config.dia < moment().day()){
                await this.getParamsGenerales()
            }
        }else{
            await this.getParamsGenerales()
        }
        
        let favIcon = document.getElementById("favicon");
        favIcon.href = this.$config.favicon;
        let colorApp = '#' + this.$config.color;
        document.documentElement.style.setProperty('--color-general', colorApp, "important");

        if(this.$usuario.id_tienda){
            this.$store.commit('home/setInvitado',false)
        }else{
            this.$store.commit('home/setInvitado',true)
        }
    },

    methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
        setLayout(layout){
            if (!layout || !layouts[layout]){
                layout = this.defaultLayout
            }

            this.layout = layouts[layout]
        },
        async getParamsGenerales(){
            await this.$store.dispatch("x_parametros/getParamsGenerales")
        }
    }
}
</script>