export default [
    {
        path: '/premios',
        name: 'premios',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/premios').then(m => m.default || m),
    },
    {
        path: '/premios/detalle/:id_premio',
        name: 'premios.detalle',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/premios/detalle').then(m => m.default || m),
    },
    {
        path: '/canjear/:id_canje',
        name: 'premios.canjear',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/premios/canje').then(m => m.default || m),
    },
]
