<template>
    <modal-lateral ref="modalChat" :titulo="titulo">
        <ValidationObserver ref="form">
            <div class="overflow-auto custom-scroll pt-3" style="height:calc(100vh - 153px);background-color:#F6F9FB;">
                <div v-for="(data, index) in mensajes" :key="index" class="row mx-0 mb-3" :class="false ? 'justify-content-start' : 'justify-content-end'">
                    <div class="p-2 f-14" :class="data.created_by != $usuario.id ? 'chat-bubble-left' : 'chat-bubble-right'">
                        <p v-if="data.tipo == 1" class="text-black space-text">
                            {{ data.mensaje }}
                        </p>
                        <div v-else class="row mx-0 j-center">
                            <img :src="data.mensaje" style="max-width:240px;height:134px;" />
                        </div>
                        <p class="f-12 text-gris2 mt-2">
                            {{ data.created_at | helper-fecha('h:mm a') }}
                        </p>
                        <!-- Arrows -->
                        <div :class="data.created_by != $usuario.id ? 'chat-bubble-arrow-left' : 'chat-bubble-arrow-right'" />
                    </div>
                </div>
            </div>
            <ValidationProvider rules="required">
                <div class="bg-white space-input-text d-middle py-2">
                    <el-upload
                    ref="adjuntos"
                    class="upload-demo f-18"
                    action="#"
                    :multiple="false"
                    :limit="1"
                    :on-change="handlePreview"
                    :on-remove="handleRemove"
                    :auto-upload="false"
                    accept="image/*"
                    :show-file-list="false"
                    >
                        <i class="icon-image cr-pointer" />
                    </el-upload>
                    <div class="col mr-2 d-middle input-chat position-relative">
                        <el-input v-model="model.mensaje" type="textarea" class="w-100 br-20" placeholder="Comentar" :autosize="{ minRows: 1, maxRows: 4}" />
                    
                        <div class="cr-pointer" @click="sendMessage">
                            <i class="icon-send-message text-black f-20" />
                        </div>
                    </div>
                </div>
            </ValidationProvider>
        </ValidationObserver>
    </modal-lateral>
</template>

<script>
import Service from '@/services/pedidos/pedidos'

export default {
    props:{
        titulo:{
            type: String,
            default: ''
        }
    },
    data(){
        return{
            mensajes: [],
            model: {
                idPedido: null,
                tipoMensaje: 1,
                mensaje: null,
                file: null,
                estado: null,
            },
            usuario: null,
        }
    },
    methods: {
        toggle(data){
            this.model.idPedido = data.idPedido;
            this.model.estado = data.estado;

            this.getData();
            this.$refs.modalChat.toggle();
        },

        async getData(){
            try{
                const params = {
                    id: this.model.idPedido,
                    estado: this.model.estado
                };
                const {data} = await Service.getChatPedido(params);
                this.mensajes = data.mensajes;
            }catch(e){
                this.errorCatch(e)
            }
        },

        async sendMessage(){
            try {
                if(!this.model.mensaje && !this.model.file)return;
                
                let model = {};
                if(parseInt(this.model.tipoMensaje) === 2){
                    model = this.crearFormData(this.model)
                } else {
                    this.model.tipoMensaje = 1;
                    model = this.model;
                }
                
                const {data} = await Service.postChatPedido(model);
                this.mensajes = [...this.mensajes, data.newMessage];
                this.$emit('update',  this.mensajes.length);
                this.clear();
            } catch(e){
                this.errorCatch(e)
            }
        },

        handlePreview(file){
            this.model.file = file.raw;
            let ext = file.raw.type.split("/");
            
            if(ext[0] != 'image'){
                return this.notificacion('Alerta', 'No es posible cargar otro tipo de archivos que no sean imagenes', 'warning');
            }
            this.model.tipoMensaje = 2;

            this.sendMessage();
        },

        handleRemove(){
            this.model.file = null;
            this.model.tipoMensaje = 1;
        },

        async clear(){
            this.model.mensaje = null;
            this.model.file = null;
            this.model.tipoMensaje = 1;
            this.$refs.adjuntos.clearFiles();
        },
    }
}
</script>
<style lang="scss" scoped>
.space-input-text{
    border-radius: 12px 12px 0px 0px;
    right: 0px;
    box-shadow: 0px 3px 6px #00000040;
}
</style>