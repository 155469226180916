import Service from "~/services/invitado/invitado";
// state
export const state = {
    tienda: JSON.parse(localStorage.getItem('tienda')) ?? {},
    carrito: JSON.parse(localStorage.getItem('carritoInvitado')) ?? [],
    favoritos: JSON.parse(localStorage.getItem('favoritosInvitado')) ?? [],
}
// getters
export const getters = {
    tienda: state => state.tienda,
    carrito: state => state.carrito,
    favoritos: state => state.favoritos,
}
// mutations
export const mutations = {
    obtenerTienda: (state, payload) => {
        state.tienda = payload
    },
    obtenerCarrito: (state, payload) => {
        state.carrito = payload
    },
    obtenerProductosFavoritos: (state, payload) => {
        state.favoritos = payload
    },
}
// actions
export const actions = {
    async getTiendaDefault({ commit }){
        const { data } = await Service.getTienda(0);
        const tienda = JSON.stringify(data.tienda);
        localStorage.setItem('tienda', tienda);
        commit('obtenerTienda', data.tienda);
    },
}