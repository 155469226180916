<template>
    <div class="menu-lateral pt-5">
        <div style="width:90px;">
            <div class="overflow-auto scroll-none menu" style="height:calc(100vh - 105px);">
                <div v-for="(item, idx) in menus" :key="idx" class="row mx-0 my-3 j-center">
                    <el-tooltip class="item" effect="light" :content="item.titulo" placement="right">
                        <router-link
                        v-slot="{ navigate, isActive }"
                        :data-xd="item.ruta"
                        :to="{ name: `${item.ruta}`, params: item.params || {} }"
                        :class="{'router-link-active': subIsActive(item.active)}"
                        >
                            <a
                            class="d-middle-center position-relative rounded-circle cr-pointer"
                            :class="{
                                'router-link-exact-active router-link-active': isActive, 
                                'router-fav p-1' : item.ruta == 'favoritos' && isActive,
                                'background-active': item.ruta != 'favoritos' && isActive
                            }"
                            @click="navigate"
                            >
                                <div v-show="item.cant>0" class="badge">
                                    {{ item.cant }}
                                </div>
                                <div v-if="item.ruta == 'favoritos'" class="bg-general d-middle-center rounded-circle w-100 h-100">
                                    <img class="obj-cover" width="100%" :src="logoLeeche" style="max-height:100%;width:50px;" />
                                </div>
                                <i v-else class="f-30" :class="item.icon" />
                            </a>
                        </router-link>
                    </el-tooltip>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            rutaActiva: '',
            menus: [
                { titulo: 'Favorito', ruta: 'favoritos', icon: 'icon-cog', cant: 0 },
                { titulo: 'Vendedores', ruta: 'leecheros', icon: 'icon-cog', cant: 0 },
                { titulo: 'Pedidos', ruta: 'pedidos', icon: 'icon-cog', cant: 0 },
                { titulo: 'Promociones', ruta: 'promociones', icon: 'icon-cog', cant: 0 },
                { titulo: 'Favoritos', ruta: 'favoritos.listar', icon: 'icon-heart-outline', cant: 0 },
                { titulo: 'Ayuda', ruta: 'ayuda', icon: 'icon-heart-outline', cant: 0 },
            ],
            logoLeeche: '/img/logo_blanco.svg'
        }
    },
    computed: {
        ...mapGetters({
            rol: 'auth/obtenerRoles',
            user: 'auth/obtenerUsuario',
        }),
        isActive(){
            return this.$route.name
        }
    },
    mounted(){
        this.menus[1].titulo = this.$config.vendedor;
    },
    methods: {
        menuSeleccionado(){
            return this.menus[0]
        },
        ir(ruta){
            this.rutaActiva=ruta
            this.$router.push({ name: ruta })
        },
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.menu-lateral{
    position: fixed;
    width: 90px;
    height: 100vh;
    z-index: 0;
    $back-menu: #F5F5F5;
    background-color: $back-menu;
}
.badge{
    position: absolute;
    top: 2px;
    left: 35px;
    border-radius: 20px!important;
    border: 1px solid #fff;
    color: #fff;
    background: linear-gradient(to right, #FF9D32 5%, #FF2525)
}
.router-fav{
    border: 1px solid var(--color-general) !important;
    img{
        width: 44px !important;
    }
}
.menu{
    a{
        width:60px;
        height:60px;
        border-radius: 50%;
        color: var(--text-general);
        i{ color: var(--color-general2); }
        i::before{ display: contents !important; }
        &:hover{
            transition: 0.5s;
            background: var(--color-general) !important;
            i{ color: #ffffff !important; }
        }
        &.router-link-active{
            /* background:#FF1720 !important; */
            i{ color: #ffffff !important; }
            color: #FFF;
        }
        &.background-active{
            background:#FF1720 !important;
            &:hover{
                background:#FF1720 !important;
            }
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
