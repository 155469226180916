<template>
    <section class="header" style="height:80px;">
        <template v-if="existeUsuario">
            <div v-if="usuario.tienda" class="navbar navbar-expand-lg d-flex border-bottom">
                <!-- like-hamburguer -->
                <div class="btn-hamburguer bg-general br-5 p-2 pr-3 mr-3 cr-pointer d-middle-center" @click="openCategorias = !openCategorias">
                    <i class="f-16 text-white" :class="`${openCategorias ? 'icon-close ml-2' : 'icon-list-menu'}`" />
                </div>
                <menu-categorias v-show="openCategorias" class="menu-logged position-absolute z-1 h-100" style="top: 80px; left:0;" />
                <!-- logo page -->
                <img :src="$config.logo" class="logo-page cr-pointer mr-xl-0 mr-xl-lg-0 mr-md-0 mr-sm-auto mr-auto" height="60" @click="irHome" />
                <!-- search -->
                <div class="col-xl-3 col-lg-4 d-md-none d-lg-block col-sm-5 col-2 search-input order-last order-lg-0">
                    <el-input v-model="buscar" prefix-icon="icon-search" size="medium" placeholder="Buscar en la tienda..." class="br-10" @input="buscarProducto" />
                </div>
                <!-- btn reward -->
                <button-recompensa v-if="!(widthWindow < 1240) && $config.menu.gaming" :monedas="usuario.gaming_monedas" :gemas="usuario.gaming_gemas" class="order-xl-3 order-lg-3 order-md-3 order-sm-5 order-5" small texto="Reclama tus recompensas" />
                <!-- direcciones -->
                <direcciones ref="componentDirecciones" class="col-sm-6 order-5 col-10 col-md-4 col-lg-3 ml-md-auto my-2" />
                
                <div class="col-auto mr-4 d-none d-md-block order-xl-6 order-lg-6 order-md-6 order-sm-4 order-4">
                    <el-tooltip :content="$config.vendedor" placement="bottom" effect="light">
                        <img
                        :src="$usuario.tienda ? $usuario.tienda.logo : '/img/no-imagenes/sin_cliente.svg'"
                        width="48" height="48"
                        class="obj-cover rounded-circle cr-pointer border"
                        @click="detalleVendedor()"
                        />
                    </el-tooltip>
                </div>
                <!-- btn card -->
                <div class="col-auto px-0 px-2 order-xl-6 order-lg-6 order-md-6 order-sm-4 order-4 text-center">
                    <div :class="`${usuario.cant_productos_carrito > 0 ? 'btn-card-wh-product' : 'btn-card'}  d-middle br-20 px-2 cr-pointer`" @click="carrito">
                        <i class="icon-cart-outline f-18" />
                        <span v-if="usuario.cant_productos_carrito > 0" class="mx-2 mt-1"> {{ usuario.cant_productos_carrito }}</span>
                    </div>
                </div>
                <div v-if="usuario.clasificacion != null" class="circled-user mx-1 d-none d-md-block order-6">
                    <el-tooltip :raw-content="true" placement="bottom" effect="light">
                        <template #content>
                            <span class="d-middle-center text-black font-weight-bold">Usuario {{ usuario.clasificacion.nombre }}</span> <br /> <span class="text-gris2">Te descontamos de {{ formatNumero(usuario.clasificacion.min_descuento) }}% a {{ formatNumero(usuario.clasificacion.max_descuento) }}% por compras superiores a {{ formatNumero(usuario.clasificacion.compra_desde, 0, true) }} </span>
                        </template>
                        <img :src="usuario.clasificacion.img_clasificacion" class="obj-cover rounded-circle" width="100%" height="100%" />
                    </el-tooltip>
                </div>
                <options-profile class="order-4 order-lg-10" @verCupones="modalCupones" @listenerAction="golistenerAction" @modalResponsive="abrirOpcionesResponsive" />
            </div>
            <div v-else class="navbar navbar-expand-lg d-flex h-100 border-bottom">
                <img :src="$config.logo" class="logo-page cr-pointer order-xl-1 order-lg-1 order-md-1 order-sm-2 order-1 mr-xl-0 mr-xl-lg-0 mr-md-0 mr-sm-auto mr-auto" height="60" />
                <direcciones ref="componentDirecciones" class="order-5 col-3 ml-auto" />
                <options-profile class="order-xl-7 order-lg-7 order-md-7 order-sm-1 order-1" @verCupones="modalCupones" @listenerAction="golistenerAction" @modalResponsive="abrirOpcionesResponsive" />
            </div>
        </template>
        <!-- invitado -->
        <template v-else>
            <div class="navbar navbar-expand-lg d-flex a-center h-100 border-bottom">
                <div class="btn-hamburguer bg-general br-5 p-2 pr-3 mr-3 cr-pointer d-middle-center" @click="openCategorias = !openCategorias">
                    <i class="f-16 text-white" :class="`${openCategorias ? 'icon-close ml-2' : 'icon-list-menu'}`" />
                </div>
                <menu-categorias-invitado v-show="openCategorias" class="menu-guest position-absolute z-1 h-100" style="top: 80px; left:0;" />
                <img :src="$config.logo" class="logo-page cr-pointer " height="60" @click="irHome" />
                <!-- buscar -->
                <div class="col-xl-3 col-lg-3 d-md-none d-lg-block col-sm-5 col-2 search-input order-last order-lg-0">
                    <el-input v-model="buscar" prefix-icon="icon-search" size="medium" placeholder="Buscar en la tienda..." class="br-20" @input="toGoQueryInvitado" />
                </div>
                <!-- ubicacion -->
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4  btn-location col-auto d-middle cr-pointer ml-auto" @click="cambiarCiudad">
                    <div class="border rounded-pill d-middle px-3">
                        <span class="text-general2 f-15 f-400 tres-puntos">
                            {{ tiendaInvitado ? tiendaInvitado.ciudad_seleccionada : 'Indefinido' }}
                        </span>
                        <i class="icon-chevron-down text-general2 f-20" />
                    </div>
                </div>
                <!-- carrito -->
                <div class="col-auto px-0 pl-2 px-lg-3">
                    <div :class="`${carritoInvitado.length < 1 ? '' : 'btn-color'} btn-card-favorito d-middle br-20 px-2 cr-pointer`" @click="irVerCarrito">
                        <i :class="`${carritoInvitado.length < 1 ? '' : 'text-white'} icon-cart-outline f-18`" />
                        <span v-if="carritoInvitado.length" class="ml-2 text-white">{{ carritoInvitado.length }}</span>
                    </div>
                </div>
                <!-- <div v-if="carritoInvitado.length < 1" class="col-auto px-0 pl-2 px-lg-3">
                    <div class="btn-card-favorito d-middle br-20 px-2 cr-pointer" @click="irVerCarrito">
                        <i class="icon-cart-outline f-18" />
                    </div>
                </div>
                <div v-else class="col-auto px-0 pl-2 px-lg-3">
                    <div class="btn-card d-middle br-20 px-2 cr-pointer btn-color" @click="irVerCarrito">
                        <i class="icon-cart-outline f-18 text-white" />
                        <span class="ml-2 text-white">{{ carritoInvitado.length }}</span>
                    </div>
                </div> -->
                <!-- opciones -->
                <div class="col-10 col-sm-7 col-lg-auto col-md mt-1 px-0 mt-1 btn-register-login">
                    <div class="d-flex" style="gap: 15px;">
                        <div class="btn-iniciar-sesion px-2 flex-fill d-middle-center text-white cr-pointer br-10" @click="$router.push({name: 'ingreso.celular'})">
                            Iniciar sesión
                        </div>
                        <div class="btn-registro  btn-secondary h-32px px-3 py-1 cr-pointer br-10 flex-fill d-middle-center" @click="$router.push({name: 'registro'})">
                            Registrarse
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <!-- Partials -->
        <modal-notificaciones ref="modalNotificaciones" />
        <modal-buscar-convenio ref="buscarConvenio" />
        <modal-opciones-responsive ref="opcionesResponsive" />
        <modal-ubicacion ref="modalUbicacion" />
        <modal-cupon ref="modalCupon" @detalleCupon="modalCuponDetalle" />
        <modal-detalle-cupon ref="modalDetalleCupon" :detalle-cupon="cupon_detalle" @irAtras="modalCupones" />
        <modal-leecheros ref="modalLeecheros" @eliminar="modalEliminarLeechero" @detalleLeechero="descripcionNuevoLeechero" />
        <modal-descripcion-nuevo-leechero ref="modalDescripcionNuevoLeechero" />
        <modal-confirmar ref="modalEliminarLeechero" :titulo="`Eliminar ${$config.vendedor}`" :mensaje="`¿Desea eliminar este ${$config.vendedor}?`" @adicional="eliminarLeechero()" />
        <modal-detalle-vendedor ref="modalDetalleVendedor" @cambiarLeechero="irVerLeecheros" />
    </section>
</template>


<script>
import Nomina from '~/services/nomina/nomina'
import Leechero from '~/services/leechero/leechero'

import { mapGetters } from 'vuex'
export default {
    components: {
        modalNotificaciones: () => import('../pages/auth/partials/modalNotificaciones'),
        modalOpcionesResponsive: () => import('../pages/auth/partials/modalOpcionesResponsive'),
        modalUbicacion: () => import('../pages/login/partials/modalUbicacion'),
        modalBuscarConvenio: () => import('../pages/convenio/partials/modalBuscarConvenio'),
        modalCupon: () => import('../pages/cupones/partials/modalCupon'),
        modalDetalleCupon: () => import('../pages/cupones/partials/modalDetalleCupon'),
        modalLeecheros: () => import('../pages/tienda/partials/modalLeecheros'),
        modalDescripcionNuevoLeechero: () => import('../pages/tienda/partials/modalDescripcionNuevoLeechero.vue'),
        menuCategorias:()=> import('../pages/categoria/components/menuCategorias.vue'),
        menuCategoriasInvitado:()=> import('../pages/invitado/categorias/components/menuCategorias.vue'),
        modalDetalleVendedor: () => import('../pages/tienda/partials/modalDescripcionLeecheros.vue'),
    },

    data(){
        return {
            openCategorias:false,
            cupon_detalle: {},
            buscar: '',
            widthWindow: '',
            login: false,
            cantProductosCarrito: 0,
        }
    },

    computed: {
        ...mapGetters({
            existeUsuario: 'auth/existeUsuario',
            usuario: 'auth/obtenerUsuario',
            tiendaInvitado: 'invitado/tienda',
            carritoInvitado: 'invitado/carrito',
            banderaAdvertencia:'asignarTiendas/banderaAdvertencia',
            viewMenuCategorias:'home/viewMenuCategorias'
        }),
        nombre(){
            return this.$route.meta.nombre
        },
        routeName(){
            return this.$route.path
        }
    },
    watch:{
        routeName(){
            this.openCategorias = false
        },
        openCategorias(value){
            this.$store.commit('home/setMenuCategorias',value)
        }
    },

    mounted(){
        this.handleResize();
        // if(this.banderaAdvertencia){
        //     this.$refs.modalAdvertencia.toggle()
        // }
        this.setAcceso()
    },

    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },

    methods: {
        carrito(){
            this.$router.push({name: 'carrito-compras'})
        },

        irVerCarrito(){
            this.$router.push({name: 'invitado.carrito'})
        },

        cambiarCiudad(){
            this.$refs.modalUbicacion.toggle('actualizar');
        },

        mostrarNotificaciones(){
            this.$refs.modalNotificaciones.toggle();
        },

        abrirOpcionesResponsive(){
            this.$refs.opcionesResponsive.toggle();
        },

        handleResize(){
            this.widthWindow = window.innerWidth;
            this.heightWindow = window.innerHeight;
        },

        async golistenerAction(action){
            switch(action){
            case "convenio":
                this.irBuscarConvenio();
                break;
            case "leecheros":
                this.irVerLeecheros();
                break;
            }
        },

        async irBuscarConvenio(){
            try {
                const {data} = await Nomina.getUserConvenio();
                if(data){
                    switch(data.convenio.estado){
                    case 0:
                    case 4:
                    case 5:
                    case 6:
                        return this.$refs.buscarConvenio.toggle();
                    case 1:
                    case 2:
                    case 3:
                        return this.$router.push({name: 'convenio', params:{ idConvenio: data.convenio.id_convenio }});
                    }
                }
                this.$refs.buscarConvenio.toggle();
            }catch(e){
                this.errorCatch(e)
            }
        },
        detalleVendedor(){
            this.$refs.modalDetalleVendedor.toggle();
        },
        async irVerLeecheros(){
            this.$refs.modalLeecheros.toggle();
        },
        async buscarProducto(){
            const busqueda = async() => {
                if (this.buscar.length > 2){
                    this.$router.push({name: 'home.tienda.busqueda', params: { query: this.buscar } })                    
                } else if(this.buscar == ""){
                    this.$router.push({name: 'home.tienda'})                    
                }
            }
            await this.delay(busqueda)
        },
        async toGoQueryInvitado(){
            const search = async() => {
                if(this.buscar.length > 2){
                
                    this.$store.commit('buscadores/setQueryProductInvitado', this.buscar);

                    let pathname = window.location.pathname;
                
                    if(pathname != '/invitado/busqueda'){
                        this.$router.replace({name:'invitado.buscar.producto'});
                    }
                
                } else if(this.buscar.length == 0){
                
                    this.$store.commit('buscadores/setQueryProductInvitado', '');
                    setTimeout(() => {
                        this.$router.push({name:'invitado'});
                    },1000);
                }       
            }
            await this.delay(search);
        },

        modalEliminarLeechero(id_tienda){
            this.id_tienda = id_tienda
            this.$refs.modalEliminarLeechero.toggle();
        },

        async eliminarLeechero(){
            try {
                const { data } = await Leechero.deleteLeechero(this.id_tienda);
                this.notificacion(
                    'Exito',
                    `${this.$config.vendedor} eliminado correctamente`,
                    'success'
                );
                this.$refs.modalEliminarLeechero.toggle();
            } catch (e){
                this.errorCatch(e)
            }    
        },

        descripcionNuevoLeechero(id_tendero){
            this.id_tendero = id_tendero;
            this.$refs.modalDescripcionNuevoLeechero.toggle(this.id_tendero);
        },

        userOn(){
            if(!_.isEmpty(this.usuario))return true;
            return false;
        },

        modalCupones(){
            this.$refs.modalCupon.toggle()
        },

        modalCuponDetalle(detalle_cupon){
            this.cupon_detalle = detalle_cupon
            this.$refs.modalDetalleCupon.toggle();
        },

        openDirecciones(){ 
            this.$refs.componentDirecciones.direcciones()
        },
        irCatalogoInvitado(){
            if(this.$route.name == 'invitado') return
            this.$router.push({name:'invitado'})
        },
        irHome(){
            if(this.$route.name == 'home') return
            this.$router.push({name:'home'})
        },
        async setAcceso(){

            if(this.existeUsuario){
                const { data } = await Leechero.guardarAccesoWeb()
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.circled-user{
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #FFFFFF;
    box-shadow: 0px 3px 6px #00000029;
}
.navbar{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    width: 100vw;
    min-height: 80px;
    background: #fff;
    color: #000000;
	//z-index: 1;
    .break-line{
        display: none;
    }
}
.hover-i li:hover{
    background: #471F9B;
    border-radius: 12px;
}

.btn-card{
    min-width: 46px;
    height: 32px;
    background-color: #FFFFFF;
    color: #637381 !important;
    box-shadow: 0px 2px 6px #0000000D;
    &-wh-product{
        min-width: 56px;
        height: 32px;
        background-color: #29D884;
        color: #FFFFFF !important;
    }
}

.btn-card-favorito{
    min-width: 45px;
    height: 32px;
    background-color: #F6F9FB;
    color: #000000 !important;
}

.btn-category{
    height: 38px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    color: #000000;
    display: flex;
    align-items: center;
}

.dropdown-special{
    border: 1px solid #DBDBDB !important;
    border-radius: 12px;
    .el-dropdown-menu__item:hover{
        background: var(--color-light) !important;
        border-radius: 5px !important;
        color: var(--color-general) !important;
    }
}

.btn-iniciar-sesion{
    box-shadow: 0px 3px 6px #00000029;
    max-width: 147px;
    height: 32px;
    border-radius: 8px;
    background-color: var(--color-general);
}
.btn-registro{
    max-width: 147px;
}

.btn-color{
    background:#29D884
}

.h-32px{
	height: 32px;
}

.btn-hamburguer{
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}


/* Media Queries */

@media (min-width: 300px) and (max-width: 1024px){
    .spacer-nav{
        display: none;
    }
    .logo-page{ 
        height: 26px; 
    }
    .header{
        height: 96px !important;
        .navbar{
            box-shadow: 0px 1px 3px #00000029;
            .user-select{
                span{
                    display: none !important;
                }
            }
        }
    }
}

@media (min-width: 300px) and (max-width: 815px) {
    // .search-input{
    //     display: none !important;
    // }
	.menu-categorias{
		top: 115px !important;
	}
}

@media (min-width: 300px) and (max-width: 560px) {
    .navbar{
        justify-content: flex-end;
    }
}




</style>
