import axios from 'axios'

const API = 'carrito'
const ENDPOINTS = {
    getInformacionCarrito(){
        return axios.get(`${API}/info-resumen`)
    },
    getInformacionProductosCarrito(){
        return axios.get(`${API}/productos-data`)
    },
    validarStockPedidos(){
        return axios.get(`${API}/validar-productos`)
    },
    addCuponCarrito(idCupon){
        return axios.post(`${API}/${idCupon}/add-cupon`)
    },
    addProducto(form){
        return axios.post(`${API}/add`,form)
    },
    updateCantidad(form){
        return axios.put(`${API}/update`,form)
    },
    eliminarProductoCarrito(idProducto){
        return axios.delete(`${API}/producto/${idProducto}`)
    },
    borrarCuponDeCarrito(){
        return axios.delete(`${API}/eliminar/cupon-carrito`)
    },
    vaciarContenidoCarrito(){
        return axios.delete(`${API}/vaciar`)
    },
    getInfoCheckout(){
        return axios.get(`${API}/checkout`)
    },
    postPagarMercadoPago(payload){
        return axios.post(`${API}/pagar-mercado-pago`, payload)
    }

};

export default ENDPOINTS;