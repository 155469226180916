export default [
    {
        path: '/recompensas',
        name: 'recompensas',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/recompensas').then(m => m.default || m),
    },
    {
        path: '/misiones-mes',
        name: 'recompensas.misiones.mes',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/recompensas/misiones').then(m => m.default || m),
    },
    {
        path: '/misiones-evento',
        name: 'recompensas.misiones.evento',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/recompensas/misionesEvento').then(m => m.default || m),
    },
]