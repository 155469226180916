import Vue from 'vue'
import Vue2Filters from "vue2-filters";
import lodash from 'lodash'
import './vee-validate'
import './draggable'
import './slim'
import './vueperslides'
import './vue-bottom'
import './google-maps'
import Global from '~/mixins/general'
import Formateadores from '~/mixins/Formateadores'
Vue.mixin(Global)
import moment from 'moment'
moment.updateLocale('es', {
    week: { dow: 0 }
})

Vue.prototype._ = lodash
Vue.mixin(Formateadores)
Vue.use(Vue2Filters)
