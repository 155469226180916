<template>
    <section class="bottom-navbar px-3">
        <div 
        v-for="(ac, a) in accesos" 
        :key="a" 
        class="col px-0 pt-2 position-relative text-center d-flex flex-column justify-content-center h-100" 
        :class="accesoActivo == ac.id ? 'text-purple' : 'text-gris2'" 
        @click="accesoActivo = ac.id;$router.push({ name: ac.ruta })"
        >
            <i :class="`${ac.icono} f-18`" />
            <p class="f-400 f-10">{{ ac.titulo }}</p>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            accesoActivo: null,
        }
    },
    computed:{
        ...mapGetters({
            isInvitado:'home/isInvitado',
        }),
        accesos(){

            if(this.isInvitado){
                return [
                    {
                        id: 1,
                        icono: 'icon-shop',
                        titulo : 'Tienda',
                        ruta: 'invitado'
                    },
                    {
                        id: 2,
                        icono: 'icon-search',
                        titulo : 'Buscar',
                        ruta: 'invitado.buscar.producto'
                    },
                    {
                        id: 3,
                        icono: 'icon-cart-outline',
                        titulo : 'Carrito',
                        ruta: 'invitado.carrito'
                    },
              
                ]
            }else{
                return [
                    {
                        id: 1,
                        icono: 'icon-shop',
                        titulo : 'Tienda',
                        ruta: 'home.tienda'
                    },
                    {
                        id: 2,
                        icono: 'icon-search',
                        titulo : 'Buscar',
                        ruta: 'home.tienda.busqueda'
                    },
                    {
                        id: 3,
                        icono: 'icon-order',
                        titulo : 'Pedidos',
                        ruta: 'pedidos'
                    },
                    {
                        id: 4,
                        icono: 'icon-cart-outline',
                        titulo : 'Carrito',
                        ruta: 'carrito-compras'
                    },
              
                ]
            }

            return []
        }
    },
}
</script>
<style lang="scss" scoped>
.bottom-navbar{
    bottom: 0px;
    width: 100vw;
    height: 54px;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 12px 12px 0px 0px;
    background-color: #FFFFFF;
    z-index: 9999;
    box-shadow: 0px 3px 6px #0000000D;
    .red-point{
        width: 6px;
        height: 6px;
        top: 4px;
        left: 44px;
        border-radius: 50%;
        background-color: #FF1720;
    }
}
</style>