export default [
    {
        path: '/promociones',
        name: 'promociones',
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/promociones/index.vue').then(m => m.default || m),
        meta: { gtm: 'Promociones' },
        
    },
    {
        path: '/promocion/:idPromocion',
        name: 'promocion.ver',
        component: () => import(/* webpackChunkName: "pedidos" */ '../../pages/promociones/promocionesVer').then(m => m.default || m),
        meta: { gtm: 'Promociones' },
        
    }
]
