export default [
    {
        path: '/tienda',
        name: 'home.tienda',
        component: () => import('../../pages/tienda/index').then(m => m.default || m),
    },
    {
        path: '/agregar-lechero',
        name: 'tienda.agregar',
        component: ()=>import('../../pages/tienda/anadirLeechero.vue').then(m=>m.default || m)
    },
    {
        path: '/tienda/busqueda/:query?',
        name: 'home.tienda.busqueda',
        component: () => import('../../pages/tienda/busquedaProductos').then(m => m.default || m),
    },
]
