import Categorias from './categorias/categorias'
import Recompensas from './recompensas/recompensas'
import Configurar from './configurar/configurar'
import Login from './login/login'
import Favoritos from './favoritos/favoritos'
import Carrito from './carrito/carrito'
import MisCanjes from './misCanjes/misCanjes'
import Convenio from './convenio/convenio'
import Pedidos from './pedidos/pedidos'
import Premios from './premio/premio'
import Cupones from './cupones/cupones'
import Invitado from './invitado/invitado'
import Tienda from './tienda/tienda'
import Promociones from './promociones/promociones'

export default [
    {
        path: '/',
        redirect: '/home',
        name: 'landing',
        //component: ()=>import('../pages/landing.vue').then(m=>m.default || m)
    },
    {
        path: '/sin-cobertura',
        name: 'sin-cobertura',
        component: ()=>import('../pages/inicio/sinCobertura.vue').then(m=>m.default || m),
    },
    {
        path: '/home',
        name: 'home',
        component: ()=>import('../pages/home/index.vue').then(m=>m.default || m),
        meta: { gtm: 'Home' },

    },
    {
        path: '/invitado',
        name: 'invitado',
        component: ()=>import('../pages/invitado/index.vue').then(m=>m.default || m),
        meta: { gtm: 'Invitado' },
    },
    ...Login,
    ...Configurar,
    ...Categorias,
    ...Carrito,
    ...Recompensas,
    ...Pedidos,
    ...Premios,
    ...MisCanjes,
    ...Favoritos,
    ...Convenio,
    ...Cupones,
    ...Invitado,
    ...Tienda,
    ...Promociones,
    {
        path: '*',
        redirect: '/home'
    },
]
