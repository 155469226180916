import Servicio from "~/services/cliente/cliente";

export const state = {
    banderaAdvertencia: false,
    respuesta: {},
};

export const getters = {
    banderaAdvertencia: state => state.banderaAdvertencia,
    respuesta: state => state.respuesta,
};

export const mutations = {
    setBanderaAdvertencia: (state, payload) => {
        state.banderaAdvertencia = payload
    }, 
    setRespuesta: (state, payload) => {
        state.respuesta = payload
    }, 
    
};

export const actions = {
    async asignarTiendas({ commit }){
        const { data } = await Servicio.asignarTiendas();
        commit('setRespuesta',data)

        if(!data.exito){
            commit('setBanderaAdvertencia',true)
        }
        return data
    }
};
