export default [
	{
		path: '/mi-perfil',
		name: 'mi-perfil',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar').then(m => m.default || m),
		meta: { gtm: 'Perfil' },
	},
	{
		path: '/creditos',
		name: 'creditos',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/creditos').then(m => m.default || m),
		meta: { gtm: 'Creditos' },
	},
	{
		path: '/creditos/deudas/ver/:id_credito',
		name: 'creditos.deudas.ver',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/detalleCredito').then(m => m.default || m),
		meta: { gtm: 'Creditos' },
	},
	{
		path: '/invita-gana',
		name: 'invita-gana',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/invitaGana').then(m => m.default || m),
	},
	{
		path: '/soporte',
		name: 'soporte',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/soporte').then(m => m.default || m),
		meta: { gtm: 'Soporte' },
	},
	{
		path: '/terminos-condiciones-usuario',
		name: 'terminos-condiciones-cliente',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/terminosCondicionesCliente').then(m => m.default || m),
		meta: { gtm: 'Termionos y Condiciones' },
	},
	{
		path: '/eliminar-cuenta',
		name: 'eliminar.cuenta',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/eliminarCuenta').then(m => m.default || m),
		meta: { gtm: 'Eliminar cuenta' },
	},
	{
		path: '/politicas-privacidad-usuario',
		name: 'politicas-privacidad-cliente',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/politicasPrivacidadCliente').then(m => m.default || m),
		meta: { gtm: 'Politica de privacidad' },
	},
	{
		path: '/historial-consultas',
		name: 'soporte.historial-consultas',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/historialConsultas').then(m => m.default || m),
	},
	{
		path: '/soporte/:id_chat',
		name: 'soporte.chat',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/soporteChat').then(m => m.default || m),
	},
	{
		path: '/soporte/solicitar',
		name: 'soporte-solicitar',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/soporteSolicitar').then(m => m.default || m),
	},
	{
		path: '/asignar-pass',
		name: 'asignar-pass',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/asignarPassword').then(m => m.default || m),
	},

	{
		path: '/asignar-phone',
		name: 'asignar-phone',
		component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/actualizarTelefono').then(m => m.default || m),
		redirect: { name: 'update-phone' },
		children: [
			{
				path: 'update',
				meta: { nombre: 'update-phone' },
				name: 'update-phone',
				component: () => import('../../pages/configurar/asignarPhoneNumber').then(m => m.default || m),
			},
			{
				path: 'verificar-codigo',
				name: 'phone-verificar-codigo',
				component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/verificarCodigoRegistro').then(m => m.default || m),
			},
		]
	},
]