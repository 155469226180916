import store from '~/store'

const VUEX_RUTA = 'auth';

export default async(to, from, next) => {
    const rutaDestino = to.name;
    //console.log(rutaDestino,'voy');
    // Consultamos el usuario e internamente validamos el token
    if(!store.getters[`${VUEX_RUTA}/existeUsuario`] && store.getters[`${VUEX_RUTA}/existeToken`]){
        try {
            await store.dispatch(`${VUEX_RUTA}/consultarUsuario`)
        } catch (e){
            return next();
        }
    }

  
    // Si el usuario logueado no tiene una tienda asignada, lo enviamos a que asigne una.
    if(store.getters[`${VUEX_RUTA}/existeUsuario`]){  
        
        if(rutaDestino === 'invitado' || rutaDestino === 'invitado.carrito' || rutaDestino === 'invitado.buscar.producto'){
            return next({ name: 'home' });
        }
        

        if(rutaDestino === 'login' || rutaDestino === 'ingreso' || rutaDestino === 'registro'){
            return next({ name: 'home' });
        }

        const user = store.getters[`${VUEX_RUTA}/obtenerUsuario`];

        if(rutaDestino == 'tienda.agregar' && user.tipo_proyecto == 1){
            return next({ name: 'home' });
        }
        if(user.tipo_proyecto == 1){ // tipo geocerca => se asignan las tiendas automaticamente
            try {
                const response = await store.dispatch(`asignarTiendas/asignarTiendas`)

                if(response.exito){
                    await store.dispatch(`${VUEX_RUTA}/consultarUsuario`)
                    return next()
                }else{
                    if(rutaDestino === 'sin-cobertura'){
                        return next()
                    }
                    return next({name:'sin-cobertura'})
                }
               
            } catch (error){
                return next({ name: 'login' });
            }
        }

        if(_.isNull(user.tienda) && rutaDestino != 'tienda.agregar'){
            return next({ name: 'tienda.agregar' });
        }
    }
    
    if(rutaDestino == 'home'){
        return next();
    }

    if(rutaDestino === 'login' || rutaDestino === 'ingreso' || rutaDestino === 'registro'){
        return next();
    }

    if(rutaDestino === 'landing'){
        return next({ name: 'home' });
    }

    // Rutas exeptas de verificación por token
    const exepto = [
        'invitado',
        'invitado.carrito',
        'registro',
        'verificar-codigo-registro',
        'recuperar-password',
        'nueva-pass',
        'verificar-codigo',
        'ingreso.celular',
        'verificar.codigo.ingreso',
        'invitado.promocion.ver',
        'terminos-condiciones-cliente',
        'politicas-privacidad-cliente',
        'invitado.buscar.producto',
        'invitado.categoria.ver',
        'invitado.categoria.ver.subcategoria',
        'eliminar.cuenta'
    ];
    let rutaExepto = exepto.find((item) => item === rutaDestino);
    if(!rutaExepto){

        if(!store.getters[`${VUEX_RUTA}/existeToken`]){
            return next({ name: 'home' });
        }
    }
    return next()
}