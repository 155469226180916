import axios from 'axios';

const API = 'productos/favoritos';

const ENDPOINTS = {
    getFavoritos(){
        return axios.get(`${API}`)
    },
    addFavorito(idProducto){
        return axios.post(`${API}`,{idProducto})
    },
    deleteFavorito(idProducto){
        return axios.delete(`${API}?idProducto=${idProducto}`)
    }
    
};

export default ENDPOINTS;