<template>
    <section class="header" style="height:80px;">
        <div :class="`navbar text-white ${$route.name == 'landing' ? 'justify-content-xl-start' : 'justify-content-xl-center'}  justify-content-lg-start justify-content-md-between justify-content-sm-center justify-content-center border-bottom`">
            <div class="col-auto px-4 spacer-nav" />
            <img :src="$config.logo" class="logo-page cr-pointer" height="60" @click="$router.push({name:'home'})" />
            <div v-if="$route.name == 'landing'" class="buttons-landing col-auto col-xl col-lg col-md-auto col-sm-auto px-0 d-flex a-center justify-content-center">
                <div class="buttons-right col-auto col-sm-auto col-md-auto col-lg col-xl px-0 d-middle justify-content-end">
                    <seleccionar-pais class="ml-xl-0 ml-lg-0 ml-md-2 ml-sm-1 ml-1 select-country" />
                    <div class="btn-iniciar-sesion text-white px-2 f-16 ml-3 cr-pointer" @click="ingresarComo">
                        Iniciar sesión
                    </div>    
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-notificaciones ref="modalNotificaciones" />
    </section>
</template>


<script>
export default {
    components: {
        modalNotificaciones: () => import('../pages/auth/partials/modalNotificaciones'),
        seleccionarPais: () => import('../pages/auth/components/seleccionarPais')
    },
    data(){
        return {
            imgUser: '/img/icons/emotes/MeEncanta.svg',
            buscar: '',
            widthWindow: 0,
            heightWindow: 0
        }
    },
    computed: {
        nombre(){
            return this.$route.meta.nombre
        },
    },
    mounted(){
        this.handleResize();
    },
    created(){
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
       
        modalCarrito(){
            this.$refs.modalCarrito.toggle();
        },
        mostrarNotificaciones(){
            this.$refs.modalNotificaciones.toggle();
        },
        ingresarComo(){
            this.$router.push({name: 'login'})
        },
        handleResize(){
            this.widthWindow = window.innerWidth;
            this.heightWindow = window.innerHeight;
            //console.log(this.widthWindow, this.heightWindow);
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    width: 100vw;
    height: 80px;
    background: #fff;
    color: #000000;
}
.hover-i li:hover{
    background: #471F9B;
    border-radius: 12px;
}
.btn-card{
    min-width: 142px;
    height: 40px;
    background-color: #000000;
    color: #FFFFFF !important;
}
.btn-category{
    height: 38px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    color: #000000;
    display: flex;
    align-items: center;
}
.dropdown-special{
    border: 1px solid #DBDBDB !important;
    border-radius: 12px;
    .el-dropdown-menu__item:hover{
        background: var(--color-light) !important;
        border-radius: 5px !important;
        color: var(--color-general) !important;
    }
}

/* Media Queries */

@media (max-width:360px) {
    .navbar{
        justify-content: center !important;
    }
    .col-auto{
        .btn-card{ display: none; }
        &.btn-location{display: none;}
    }
    .logo-page{ height: 35px; }
    
}

.countrys{
    position: relative;
    .options-countrys{
        border: 1px solid #DBDBDB;
        position: absolute;
        /* height: 230px; */
        width: 184px;
        left: -19px;
        top: 30px;
        border: 1px solid #DBDBDB;
        border-radius: 8px;
    }
}


.btn-iniciar-sesion{
    width: 181px;
    height: 44px;
    border-radius: 12px;
    background-color: var(--color-general);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
        box-shadow: 0px 3px 6px #00000029;
    }
}

// Media Queries

@media (min-width: 300px) and (max-width: 1024px){
    .header{
        height: 96px !important;
        .navbar{
            box-shadow: 0px 1px 3px #00000029;
            height: 100%;
            .user-select{
                span{
                    display: none !important;
                }
            }
        }
    }
    .spacer-nav{
        display: none;
    }
}


@media (min-width: 300px) and (max-width: 905px){
    .logo-page{ 
        height: 30px; 
    }

}

@media (min-width: 300px) and (max-width: 815px) {
    .buttons-landing{
        .buttons-right{
            .btn-iniciar-sesion{ display: none !important; }
        }
    }
}
@media (min-width: 300px) and (max-width: 560px) {
    .navbar{
        justify-content: center;
        .countrys{
            span{ display: none !important; }
        }    
    }
}
</style>
