import Parametros from "~/services/x_parametros";
import moment from "moment";

export const state = {
    configuracion: localStorage.configuracion ? JSON.parse(localStorage.configuracion) : {},
    imgGenerales:localStorage.imgGenerales ? JSON.parse(localStorage.imgGenerales) : {},
    imgCliente:localStorage.imgCliente ? JSON.parse(localStorage.imgCliente) : {},

};

export const getters = {
    configuracion: state => state.configuracion,
    imgGenerales: state => state.imgGenerales,
    imgCliente: state => state.imgCliente,
};

export const mutations = {
    setParamsGenerales: (state, payload) => {
        payload.dia = moment().day();
        state.configuracion = payload;
        localStorage.configuracion = JSON.stringify(payload)
    },
    setImagenesGenerales: (state, payload) => {
        state.imgGenerales = payload;
        localStorage.imgGenerales = JSON.stringify(payload) 
    },
    setImageCliente: (state, payload) => {
        state.imgCliente = payload;
        localStorage.imgCliente = JSON.stringify(payload);
    }
};

export const actions = {
    async getParamsGenerales({ commit }){
        const { data } = await Parametros.getParamsGenerales();
        commit("setParamsGenerales", data.parametros);
        commit("setImagenesGenerales", data.imagenes);
        commit("setImageCliente", data.cliente);
    },
};
