import axios from 'axios';

const API = 'nomina';

const ENDPOINTS = {
    getSearchConvenio(query){
        return axios.get(`${API}/convenio/${query}/search`)
    },
    getConvenio(id){
        return axios.get(`${API}/${id}/convenio`)
    },
    postSolicitudConvenio(model){
        return axios.post(`${API}/convenio/solicitud`, model)
    },
    deleteSolicitudConvenio(id){
        return axios.delete(`${API}/${id}/convenio/solicitud`)
    },
    desvincularClienteConvenio(){
        return axios.post(`${API}/convenio/desvincular`)
    },
    getUserConvenio(){
        return axios.get(`${API}/convenio/usuario`)
    },
};

export default ENDPOINTS;