<template>
    <vue-bottom-sheet ref="mobileBottomSheet" :overlay="true">
        <div class="row mx-0 my-2 j-center">
            <div class="col-auto text-black f-17 f-500">
                {{ titulo }}
            </div>    
        </div>
        <slot />
        <div class="row mx-0 j-center py-2">
            <button
            v-if="!noCancelar"
            type="button"
            class="btn-cancelar mx-2"
            @click="actionCancelar"
            v-text="textosBotones.cancelar"
            />
            <button
            v-if="!noAceptar"
            type="button"
            class="btn-adicional mx-2"
            @click="actionAceptar"
            v-text="textosBotones.aceptar"
            />
            <div
            v-if="adicional !== ''"
            type="button"
            class="btn-adicional d-flex a-center j-center mx-2"
            @click="onAdicional"
            v-text="adicional"
            />
            <!-- <slot name="buttonSpecific" /> -->
        </div>
    </vue-bottom-sheet>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        noCancelar: {
            type: Boolean,
            default: false
        },
        noAceptar: {
            type: Boolean,
            default: false
        },
        adicional: {
            type: String,
            default: ''
        },
    },
    data(){
        return {
            textosBotones: {
                aceptar: 'Aceptar',
                cancelar: 'Cancelar',
                cerrar: 'Cerrar'
            }
        }
    },
    methods: {
        actionAceptar(){
            this.$emit('aceptar')
        },
        actionCancelar(){
            this.$emit('eliminar')
        },
        onAdicional(){
            this.$emit('adicional')
        },
        toggle(){
            this.$refs.mobileBottomSheet.open();
        },
    }
}
</script>