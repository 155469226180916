<template>
    <div class="card-producto bg-white px-2 py-2 br-12 cr-pointer mx-0 j-center mx-2 pb-2 mb-1 position-relative" :class="fullWidth ? 'full-width-card' : ''">
        <div class="row mx-0 j-center mb-2" @click="verProducto">
            <img :src="producto.imagen ? producto.imagen : '/img/no-imagenes/mercado.svg'" class="obj-cover" style="height:190px;width:160px;" />
        </div>
        <div class="bg-white circled-heart d-flex rounded-circle a-center j-center position-absolute" @click="agregarFavorito">
            <i :class="`${favorito ? 'icon-heart text-red' : 'icon-heart-outline text-gris2'} cr-pointer`" />
        </div>
        <div class="row mx-0 prices mb-2 justify-content-center" @click="verProducto">
            <span v-if="producto.promocion" class="text-general f-12 f-600">{{ formatNumero(totalFinal, 0, true) }}</span>
            <span v-else class="text-general f-13 f-600">{{ formatNumero(totalTeorico, 0, true) }}</span>
            <span v-show="producto.promocion" class="text-gris2 ml-2 descuento f-12">{{ formatNumero(totalTeorico, 0, true) }}</span>
        </div>
        <div v-if="producto.promocion" class="row mx-0 j-center mb-2">
            <span class="bg-general br-10 text-white px-2 f-12">{{ producto.data_promocion.texto }}</span>
        </div>
        <div class="row mx-0 name-product" @click="verProducto">
            <p class="col-12 px-0 text-general nombre-producto f-14">{{ producto.nombre }}</p>
            <p class="col-12 px-0 f-12 text-general2 mt-2">{{ producto.presentacion }}</p>
        </div>
        <div class="row mx-0 mt-2">
            <div class="col-12 px-0 f-12">
                <div v-if="cant_product <= 0" class="button-card d-middle-center br-50" @click="cant_product += 1">
                    Añadir
                    <i class="icon-cart-outline f-18" />
                </div>
                <div v-else class="d-middle button-cant">
                    <div class="minus" @click="cant_product -= 1">
                        <i class="icon-minus f-12" />
                    </div>
                    <div class="space-cant d-middle-center f-12 col px-0">
                        {{ cant_product }}
                    </div>
                    <div class="plus" @click="cant_product += 1">
                        <i class="icon-plus f-12" />
                    </div>
                </div>
            </div>
        </div>
        <modal-producto ref="modalProducto" @change="changeCarrito" @changeFavorito="changeFavorito" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    components: {
        modalProducto: () => import('~/pages/invitado/partials/modalProductoInvitado'),
    },
    props: {
        mostrarDetalle:{
            type: Boolean,
            default: true
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        producto: {
            type: Object,
            default: () => {}
        },
    },
    data(){
        return {
            favorito: false,
            cant_product: 0,
            showCant: false
        }
    },
    computed: {
        ...mapGetters({
            carrito: 'invitado/carrito',
            favoritos: 'invitado/favoritos',
        }),
        totalTeorico(){
            if(this.cant_product == 0){
                return this.producto.precio
            }
            return this.producto.precio * this.cant_product
        },
        totalFinal(){
            if(this.cant_product == 0 && this.producto.promocion){
                return this.producto.data_promocion.promo_valor
            }

            if(this.cant_product == 0 && !this.producto.promocion){
                return this.producto.precio
            }

            if(this.producto.promocion){
                return this.producto.data_promocion.promo_valor * this.cant_product
            }else{
                return this.producto.precio * this.cant_product
            }
        }
    },
    watch: {
        cant_product(){
            this.asignarProducto();
        },
    },
    mounted(){
        if(JSON.parse(localStorage.getItem('carritoInvitado'))){
            this.actualizarCantidadProducto();
        }
        this.esFavorito();
    },
    methods: {
        asignarProducto(){
            let carritoInvitado = JSON.parse(localStorage.getItem('carritoInvitado'));
            let productos = carritoInvitado != null ? carritoInvitado : [];
            if(productos.length > 0){
                let producto = productos.find((e) => e.id_producto === this.producto.id_producto);
                if(producto){
                    let index = productos.findIndex(e => e.id_producto === this.producto.id_producto);
                    if(this.cant_product < 1){
                        return this.removerProductoCarrito(productos, index);
                    }
                    return this.actualizarProductoCarrito(productos, index);
                }
            }
            this.asignarProductoCarrito(productos);
        },
        asignarProductoCarrito(productos){
            let dataProducto = {};
            dataProducto.id_producto = this.producto.id_producto;
            dataProducto.cantidad = this.cant_product;
            productos.push(dataProducto);
            const asignar = JSON.stringify(productos);
            // Actualizamos el carrito en cache del navegador
            localStorage.setItem('carritoInvitado', asignar);
            // Actualizamos el carrito en vuex
            this.carrito.push(dataProducto);
        },
        actualizarProductoCarrito(productos, index){
            productos[index].cantidad = this.cant_product;
            this.carrito[index].cantidad = this.cant_product;
            const asignar = JSON.stringify(productos);
            localStorage.setItem('carritoInvitado', asignar);
            return;
        },
        removerProductoCarrito(productos, index){
            productos.splice(index,1);
            this.carrito.splice(index, 1);
            const asignar = JSON.stringify(productos);
            localStorage.setItem('carritoInvitado', asignar);
            let esVacio = JSON.parse(localStorage.getItem('carritoInvitado'));
            if(esVacio.length < 1){
                localStorage.removeItem('carritoInvitado');
            }
        },
        async actualizarCantidadProducto(){
            let carritoInvitado = JSON.parse(localStorage.getItem('carritoInvitado'));
            if(carritoInvitado.length > 0){
                let producto = carritoInvitado.find((e) => e.id_producto === this.producto.id_producto);
                if(producto){
                    this.cant_product = producto.cantidad;
                }
            }
        },
        verProducto(){
            this.$refs.modalProducto.toggle(this.producto);
        },
        agregarProducto(){
            this.cant_product = this.cant_product + 1;
        },
        minusCant(){
            this.cant_product = this.cant_product - 1;
        },
        deleteCant(){
            this.cant_product = 0
        },
        esFavorito(){
            setTimeout(() => {
                let esFavorito = this.favoritos.find((item) => item.id_producto === this.producto.id_producto);
                if(esFavorito){
                    this.favorito = !this.favorito;
                }
            },100);
        },
        agregarFavorito(){
            this.favorito = !this.favorito;
            let productosFavoritos = this.favoritos;
            if(this.favorito){
                // Añadimos el producto a favoritos
                let data = { id_producto: this.producto.id_producto };
                productosFavoritos.push(data);
                const asignar = JSON.stringify(productosFavoritos);
                localStorage.setItem('favoritosInvitado', asignar);
                this.$store.commit('invitado/obtenerProductosFavoritos', productosFavoritos);
            }else{
                // Quitamos el producto de favoritos
                let index = productosFavoritos.findIndex(e => e.id_producto === this.producto.id_producto);
                productosFavoritos.splice(index, 1);
                const asignar = JSON.stringify(productosFavoritos);
                localStorage.setItem('favoritosInvitado', asignar);
                this.$store.commit('invitado/obtenerProductosFavoritos', productosFavoritos);
            }
        },
        changeCarrito(){
            let data = this.carrito.find((item) => item.id_producto === this.producto.id_producto);
            if(data){
                return this.cant_product = data.cantidad;
            }
            this.cant_product = 0;
        },
        changeFavorito(bandera){
            if(bandera === 'agrego'){
                this.favorito = true;
            }else{
                this.favorito = false;
            }
        },
        anadirCantidad(){
            this.showCant = !this.showCant
            this.cant_product++
        },
    }
}
</script>
<style lang="scss" scoped>
.card-producto{
    width: 240px;
    min-height: 228px;
    box-shadow: 0px 3px 6px #0000000D;

    &.full-width-card{
        width: 100% !important;
    }

    .circled-heart{
        width:24px;
        height:24px;
        top:10px;
        right:16px;
        box-shadow: 0px 2px 6px #0000000D;
    }
    .nombre-producto{
        height: 38px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
    }
    .btn-add{
        height: 32px;
    }
    .button-card{
        height: 30px;
        color: #FFFFFF;
        background-color: var(--color-general);
        font-size: 14px;;
        border-radius: 4px;
    }
    .button-cant{
        .minus{
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            color: var(--color-general);
            border: 1px solid var(--color-general);
        }
        .space-cant{
            color: #000000;
        }
        .plus{
            width: 24px;
            height: 24px;
            color: #FFFFFF;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: var(--color-general);
        }
    }
}
</style>
