import axios from 'axios'
import store from '../store'
import router from '../router'

const VUEX_AUTH = 'auth'

axios.defaults.baseURL = process.env.VUE_APP_URL

// Request interceptor
axios.interceptors.request.use(request => {
    const token = store.getters[`${VUEX_AUTH}/obtenerToken`]
    if (token) request.headers.common['Authorization'] = `Bearer ${token}`

    const locale = store.getters['lang/locale']
    if (locale) request.headers.common['Accept-Language'] = locale

    // request.headers['X-Socket-Id'] = Echo.socketId()
    return request
})

// Response interceptor
axios.interceptors.response.use(response => response, error => {
    const { status } = error.response

    if (status >= 500) console.error(error)

    if (status === 401){
        store.dispatch('auth/CERRAR_SESION')
        router.push({ name: 'home' })
    }

    return Promise.reject(error)
})
