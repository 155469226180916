import axios from 'axios';

const ENDPOINTS = {
    getHome(params){
        return axios.get(`home`,{params})
    },
    guardarClick(idElemento){
        return axios.post(`home/elemento/${idElemento}/visita`)
    }, 
    guardarVisita(){
        return axios.post(`home/landing/visita`)
    },
    sincronizarCarrito(form){
        return axios.post(`home/sync/cart`,form)
    },
    getFooter(){
        return axios.get(`footer`)
    },
};

export default ENDPOINTS;